import React, {useState, useEffect} from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import '../../styles/style.css';
import Moment from 'moment';
import 'moment/locale/es'
import validator from 'validator';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
    EventName,
    getDominio,
    getOtherDominio,
    getPasarelaPago,
    modalError,
    publish,
    subscribe,
    unsubscribe
} from '../Payment/Request'
import {HeaderView} from '../../components/HeaderView'
import Cart from '../../models/Cart'
import {IPasarelaPago} from '../../models/PasarelaPago'
import {deleteCart, deleteItemFromCart2, getCart, getClientData} from './Request'
import {CartItem} from './CartItem'
import {FormDataBooking} from './FormDataBooking'
import CartTotal from './CartTotal';
import { FooterView } from 'components/FooterView';
import { MultiSafePay } from 'Modules/Payment/MultiSafePay';
import { TrustMyTravel } from 'Modules/Payment/TrustMyTravel';
import Swal from 'sweetalert2';


import { Greenpay } from 'Modules/Payment/Greenpay';
import "../../components/Greenpay.css";

import { Stripe } from 'Modules/Payment/Stripe';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from 'components/CheckoutForm';
import { loadStripe } from '@stripe/stripe-js/pure';
import * as Sentry from "@sentry/react";
import { PaynoPain } from 'Modules/Payment/PaynoPain';
import { useInitIdioma } from 'customHooks/useInitIdioma';
import { Credomatic } from 'Modules/Payment/Credomatic';


declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
      // extends React's HTMLAttributes
      gpsession?: string;
      gptoken?: string;
      environment?: string;
    }
  }

interface CartParams {
    stripe: any;
    public: string;
    data?: string;
}

type CartProps = RouteComponentProps<CartParams>;

export const CartPage: React.FC<CartProps> = (props: CartProps) => {

    const publicToken = props.match.params.public;
    const history = useHistory(); 
    const {idioma, t, i18n} = useInitIdioma(publicToken) // Custom Hook

    // TODO mejor recibirlo por endpoint??
    const PAYNOPAIN: IPasarelaPago = {
        id: 1, name: 'PAYNOPAIN'
    }

    const TRUSTMYTRAVEL: IPasarelaPago = {
        id: 2, name: 'TRUSTMYTRAVEL'
    }

    const MULTISAFEPAY: IPasarelaPago = {
        id: 3, name: 'MULTISAFEPAY'
    }

    const GREENPAY: IPasarelaPago = {
        id: 4, name: 'GREENPAY'
    }

    const STRIPE: IPasarelaPago = {
        id: 5, name: 'STRIPE'
    }

    const CREDOMATIC: IPasarelaPago = {
        id: 6, name: 'CREDOMATIC'
    }

    // TODO de momento a código duro, en el backoffice se configurarán más campos
    const items = [
        {name: 'name', required: true, placeholder: `${t("name")}`},
        {name: 'surname', required: true, placeholder: `${t("surname")}`},
        {name: 'telephone', required: true, placeholder: `${t("phone")}`},
        {name: 'email', required: true, placeholder: `${t("email")}`},
        {name: 'additionalInformation', required: false, placeholder: `${t("additionalInformation")}`},
    ]

    let uuid;
    try {
        uuid = localStorage.getItem('tokenTicket');
    } catch(e) {
        history.push(`/${publicToken}/cookies`)
    }

    const [cart, setCart] = useState<Cart>();
    const [dataIncorrecto, setDataIncorrecto] = useState<boolean>(false);
    const [emailIncorrecto, setEmailIncorrecto] = useState<boolean>(false);
    const [telefonoIncorrecto, setTelefonoIncorrecto] = useState<boolean>(false);
    const [openDataProblemPopup, setOpenDataProblemPopup] = useState<boolean>(false);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [money, setMoney] = useState<string>();
    const [clientName, setClientName] = useState<string>('');
    const [dominio, setDominio] = useState<string>();
    const [pasarelaPago, setPasarelaPago] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true)
    const [tokenTicketDeleted, setTokenTicketDeleted] = useState<boolean>(false)
    const [checked, setChecked] = React.useState(false);
    const [client, setClient] = React.useState<any>();
    //const [existsTerms, setExistsTerms] = useState<boolean>(false) ;
    let existsTerms = false;
    const [loadedExists, setLoadedExists] = useState<boolean>(false)
    const [deleting, setDeleting] = useState<any>(undefined)
    const [numero, setNumero] = useState<any>(undefined)

    const [openGreenPay,setOpenGreenPay] = useState<boolean>(false)
    const [dataGreenpay,setDataGreenpay] = useState<any>(undefined)
    const [openStripe, setOpenStripe] = useState<boolean>(false)
    const [dataStripe, setDataStripe] = useState<any>()
    const [stripePromise, setStripePromise] = useState<any>()

    const [adding, setAdding] = useState<boolean>(undefined);
    const [additionalRequiredInformation, setAdditionalRequiredInformation] = useState<boolean>(false)
    const [telephoneFormat, setTelephoneFormat] = useState<string>()

    const initialDevelop = {
        name: '',
        surname: '',
        telephone: '',
        email: ''
    }

    // const [dataBooking, setDataBooking] = useState<{[name: string]: string}>(dataBookingInitialState)
    const [dataBooking, setDataBooking] = useState<{[name: string]: string}>(initialDevelop)

    let additional_info = "";
    const [url_post, setUrl_post] = useState<any>();

    const [openCreditCard, setOpenCreditCard] = useState<any>(false) // Para la pasarela de pago Credomatic
    const [credomaticInfo, setCredomaticInfo] = useState<any>({})
    const [additional_info_credomatic, setAdditinal_info_credomatic] = useState<any>()
    const isCredomatic = pasarelaPago === 'CREDOMATIC'

    // TODO comentado para pruebas, baseURLTicando es produccion
    //const urlBasePost = `${baseURLTicando}/paymentcart/result?public=${publicToken}&cartToken=${uuid}`
    const urlBasePost = `${process.env.REACT_APP_baseURLTicando}/paymentcart/result?public=${publicToken}&cartToken=${uuid}`
    // let url_post = `${baseURLTicando}/paymentcart/result?public=${publicToken}&cartToken=${uuid}`
    
    // TODO ¿por seguridad mejor rellenarlo en el back?
    let url_ok_base = "https://ticket.ticando.net/";
    let url_ko = ''
    if(process.env.REACT_APP_ENVIRONMENT === 'dev') {
        url_ko = `https://${process.env.REACT_APP_SUBDOMINIO_DEV}/${publicToken}/cart/`;
    }else if(process.env.REACT_APP_ENVIRONMENT === 'prod'){
        url_ko = `https://${process.env.REACT_APP_SUBDOMINIO_PROD}/${publicToken}/cart`
    }


    const listener = (data) => {
        setAdding(false)
    }

    useEffect(() => {
        subscribe(EventName.OnFinish, listener)
        return () => {
            unsubscribe(EventName.OnFinish, listener)
        }
    }, [])


    useEffect(() => {
        if(!client) {
            getClientData(publicToken).then(res => {
                setClient(res.client)

                // Obtengo la clave publico de Stripe
                if(res.PUBLISHED_APIKEY_STRIPE) {
                  setStripePromise(loadStripe(res.PUBLISHED_APIKEY_STRIPE));
                }

                if(JSON.parse(res.client.terms) && JSON.parse(res.client.terms).custom && JSON.parse(res.client.terms).custom[idioma] !== "" && JSON.parse(res.client.terms).custom[idioma] !== "<p></p>\\n"){
                   existsTerms = true;
                } else {
                    existsTerms = false;
                }
                setLoadedExists(true)
            })
        }
    },[client])

    useEffect((() => {
        i18n.changeLanguage(idioma);  
        let error = new URLSearchParams(window.location.search).get("error")
        if (error) modalError('error')      
    
        if (!cart) {
            Moment.locale('es'); 
            if (uuid) {
                getCart(uuid, publicToken)
                    .then((res) => {
                        if (res.code && res.code === 433) { // 433: carrito ya cobrado
                            try {
                                localStorage.removeItem('tokenTicket')
                            } catch(e) {
                                console.log(e)
                            }
                        }
                       if (res.statusCode === 401) {
                            Sentry.captureException(new Error("CartPage - Cliente no encontrado"));
                            modalError(t("Client not found"))
                            return null
                        } else if(res.statusCode === 404) { 
                            Sentry.captureException(new Error("CartPage - Cart"));
                            modalError(t("occursError"))
                            return null                    
                        }
                        setLoading(false);
                        if (!res.error) {


                            let aux_additionalrequiredinformation = false
                            res.cartTickets.forEach((cartTicket => {
                                if(cartTicket.additionalrequiredinformation) {
                                    aux_additionalrequiredinformation = true
                                }
                            }))

                            res.cartCollaboratorTickets.forEach((cartCollaboratorTicket => {
                                if(cartCollaboratorTicket.additionalrequiredinformation) {
                                    aux_additionalrequiredinformation = true
                                }
                            }))

                            setAdditionalRequiredInformation(aux_additionalrequiredinformation)

                            // TODO Código duro packetPriceNet
                            res.cartPacketTickets.forEach(cartPacketTicket => {
                                cartPacketTicket.packetTicket.collaboratorTickets.forEach(collaboratorTicket => {
                                    collaboratorTicket.stretch.prices.forEach(price => {
                                        price.packetPriceNet = +((price.priceNet - price.priceNet * 0.1).toFixed(4))
                                    })
                                })
                            })
                            setCart(res);
                            setMoney(res.money)
                            setClientName(res.clientName)
                            if (res.contact) {
                                const temp = res.contact
                                // teléfono da error si se le mantiene el valor al necesitar country para la validación
                                temp.telephone = ''
                                setDataBooking(temp)
                            }
                            localStorage.setItem('clientName',res.clientName)
                        }
                    })
                    .catch(reason => {
                        console.log('error: ', reason)
                    })
            } else {               
                setLoading(false)
            }
        } else {
            setMoney(cart.money)
            setClientName(cart.clientName)
            const subtotal = getTotal(cart)
            setTotalAmount(subtotal);
        }

        /*if(!clientName) {
            getClientName(+ticando_id).then((res) => {
                setClientName(res[0].Nombre);
            });
        }*/
        return () => {window.removeEventListener('storage',()=>{

        })}

    }) , [cart, totalAmount, money, clientName,deleting/*uuid, cart, clientName, pasarelaPago, publicToken, dataBooking*/]);

    useEffect(() => {
        if (!pasarelaPago) {
            getPasarelaPago(publicToken).then((res) => {
                setPasarelaPago(res[0].name);
            }).catch(e => {
               Sentry.captureException(new Error("CartPage - errorGetPasarela "+ e));
               history.push(`/${publicToken}/error/${t("errorGetPasarela")}`)
            });
        }
    },[pasarelaPago])

    useEffect(() => {
        if (!dominio) {
            let uuid_dmn = localStorage.getItem('uuid_dmn')
            let dominio_origin = dominio
            if (uuid_dmn && uuid_dmn !== 'dmn') { // Hay uuid_dmn, por lo que se obtiene la url de la tabla urls_clientes
                getOtherDominio(publicToken, uuid_dmn).then(r => {
                    setDominio(JSON.parse(r).url)
                })
            } else { // No hay uuid_dmn, se obtiene el dominio principal como hasta ahora
                getDominio(publicToken).then((res) => {
                    setDominio(res)
                }).catch(e => {
                    Sentry.captureException(new Error("CartPage - getDominio"));
                    history.push(`/${publicToken}/error/Ha ocurrido un error - getDominio`)
                })
            }
        }
    },[dominio])

    const closeClickDataProblemHandler = () => {
        setOpenDataProblemPopup(false);
    };

    const handleOpenStripe = () => {
        publish(EventName.OnFinish, null)
        setOpenStripe(false)
    };

    const handleChangeCheked = () => {
        setChecked(!checked);
    };

    const clickTerminos = () => {
      Swal.fire({
        title: `${t('termsConditions')}`,
        html: JSON.parse(client.terms).custom[idioma],
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        }
      })
    }

    const handleChange = (name: string, value: string) => {
        const temp = {...dataBooking}
        temp[name] = value
        setDataBooking({
            ...temp
        })
    }

    const handleDeleteCart = () => {
        deleteCart(uuid, publicToken).then(res =>{
            // Ha ido bien
            if (res.code === 200) {
                setCart(undefined)
            } else if(res.code === 419) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: t('cartPaid'),
                })
            } else if (res.code === 420) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: t('cartnoExists'),
                })
            }
        });

    }
    
    const handleDeleteItem = (numero) => {
        // Control de errores hecho con el else
        setDeleting(true)
        setNumero(numero)
        deleteItemFromCart2(numero, publicToken).then((response: {result: string, code: number, message: string}) => {
            setDeleting(false)
            if(response.code === 414) {
                Sentry.captureException(new Error("CartPage - El número de ticket no existe"));
                history.push(`/${publicToken}/error/${"El número de ticket no existe."}`);
            } else if(response.code > 0) {
                getCart(uuid, publicToken).then((res) => {
                    
                    setCart(res);
                });
            } else {
                Sentry.captureException(new Error("CartPage - Delete"));
                history.push(`/${publicToken}/error/${"Hemos obtenido un error inesperado. - Delete"}`);
            }
        })
    }
    
    const handlePaymentOk = (data) => {
        history.push(`/${publicToken}/payment/success`)       
    }
   
    const handlePaymentError = (data) => {
        console.log('cartPage handlerError - data: ', data)
        // alert(`se ha producido un error en el pago, por favor inténtelo de nuevo`)
    }

    const sendPayment = async () => {
        let {name, surname, telephone, email} = dataBooking
        setAdding(true)
        let tokenTicket;
        try {
            tokenTicket = localStorage.getItem('tokenTicket');
        } catch(e) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Es necesario activar las cookies de terceros para poder realizar la reserva.',
                footer: `Ayuda: En Chrome dirígete a chrome://settings/cookies para permitirlo.`
              }) 
        }
        if(!tokenTicket){
            return null;
        }
        // envío datos de contacto
        // sendContact(data)
        const baseURLTicando = process.env.REACT_APP_baseURLTicando
        const request = await fetch(
            `${baseURLTicando}/tokenticket/additionalInfo/${uuid}`,
            {
                headers: {
                  'Content-Type': 'application/json',
                  'x-provider': 'creaticket',
                  'Authorization': `Bearer ${publicToken}`
                },
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify(dataBooking)
            }
        )
        
        // Controlo que ningun item del carrito haya expirado
        let i = 0;
        let expired = false;
        const tickets = [...cart.cartTickets, ...cart.cartCollaboratorTickets, ...cart.cartPacketTickets, ...cart.cartCollaboratorPacketTickets]
        while(i<tickets.length && !expired) {
            if (tickets[i].expire_at !== null) {
                if (new Date(tickets[i].expire_at) < new Date()) { // Ha expirado
                    expired = true;
                }
            } else {
                const treintaMins = new Date(tickets[i].created_at);
                treintaMins.setSeconds(treintaMins.getSeconds()+30*60)
                if (treintaMins < new Date()) {
                    expired = true;
                }
            }
            i++;
        }

        if (expired) { // Algun elemento está expirado
            Swal.fire({
                title: `${t("expiredItem")}`,
                text: `${t("expiredItemmsg")}`,
                icon: 'error'
            })
            return null;
        }

        if (name !== "" && telephone !== "" && email !== "") {
            telephone = telephone.replaceAll(" ", "")
            //if (!validator.isMobilePhone(telephone.replace(" ", ""), 'any') || !validator.isEmail(email)) {
            if (telephone.length < telephoneFormat.length || !validator.isEmail(email)) {
                setDataIncorrecto(true);
                if(!validator.isEmail(email)) setEmailIncorrecto(true);
                if(telephone.length < telephoneFormat.length) {
                    Sentry.captureException(new Error("Teléfono incorrecto "+telephone));
                    setTelefonoIncorrecto(true);
                }
              //  if(!validator.isMobilePhone(telephone.replace(" ", "").replace("(", "").replace(")", "").replace("-", ""), 'any')) setTelefonoIncorrecto(true);
            } else {
                getPasarelaPago(publicToken).then(async (res) => {
                    setPasarelaPago(res[0].name);
                    if(res[0].name === CREDOMATIC.name) {
                        setCredomaticInfo(res[0])
                    }
                    let pasarela_pago = res[0].name
                    if (pasarela_pago) {
                        if (cart && publicToken && !cart.message) {
                            let numeros: string[] = [];
                            let url_ok = `${url_ok_base}?client=${publicToken}&`;
                            numeros.forEach((numero) => {
                                url_ok += `numeros=${numero}&`;
                            });
                            url_ok = url_ok.substr(0, url_ok.length - 1);
                            
                            const description = `${name} ${surname} - ${telephone} - ${email}`;
    
                            url_ok = `https://ticket.ticando.net/?client=${clientName}&numero=${uuid}`
                            let uuid_aux;
                            try {
                                uuid_aux = localStorage.getItem('tokenTicket')
                            } catch(e) {
    
                            }
                            additional_info = JSON.stringify({
                                //cart: cart, // Para prueba de multisafepay se ha quitado
                                url: url_ok,
                                uuid: uuid_aux, 
                                //tokenTicket,
                               // description,
                                dataBooking
                            });
    
                            let body;
                            let pasarela;
                            //pasarela_pago = 'CREDOMATIC'
                            switch(pasarela_pago) {                           
                                case PAYNOPAIN.name:       
                                    pasarela = new PaynoPain(tokenTicket, publicToken, totalAmount, description, name, surname, telephone, email, additional_info, money, cart, t)
                                    pasarela.set_url_post(`${urlBasePost}&pasarela=${PAYNOPAIN.name}`);
                                    // pasarela.set_url_post(`${process.env.REACT_APP_baseURL}${process.env.REACT_APP_PREFIX}/pruebaresultMultisafepay?publicToken=${publicToken}`)
                                    url_ok = `http://localhost:3002/${publicToken}/payment/success`
                                    //url_ko = `http://localhost:3002/${publicToken}/payment/error`
                                    url_ko = `http://localhost:3002/${publicToken}/payment/processing?errorPaynoPain=error`

                                    pasarela.set_url_ok(url_ok)
                                    pasarela.set_url_ko(url_ko);
                                   
                                    pasarela.pay()
                                    /*url_post += `&pasarela=${PAYNOPAIN.name}`;
                                    body = mountPaynoPainBody(signature, totalAmount*100, customer_ext_id, additional_info, service_uuid, url_post, url_ok, url_ko, description);

                                    getPaymentPage(restult.token).then((result) => {
                                    // Redirecciona a la url
                                    window.location.href = result;
                                    });*/
                                    break;

                                case MULTISAFEPAY.name:     
                                    if(process.env.REACT_APP_ENVIRONMENT === 'dev') {
                                        url_ok = `https://${process.env.REACT_APP_SUBDOMINIO_DEV}/${publicToken}/payment/processing`
                                    } else if(process.env.REACT_APP_ENVIRONMENT === 'prod') {
                                        url_ok = `https://${process.env.REACT_APP_SUBDOMINIO_PROD}/${publicToken}/payment/processing`
                                    }
        
                                    pasarela = new MultiSafePay(tokenTicket, publicToken, totalAmount, description, name, surname, telephone, email, additional_info, money, cart, t)
                                    pasarela.set_url_post(`${urlBasePost}&pasarela=${MULTISAFEPAY.name}`);
                                    // pasarela.set_url_post(`${process.env.REACT_APP_baseURL}${process.env.REACT_APP_PREFIX}/pruebaresultMultisafepay?publicToken=${publicToken}`)
                                    // url_ok = `http://localhost:3002/${publicToken}/payment/processing`
                                    pasarela.set_url_ok(url_ok)
                                    pasarela.set_url_ko(url_ko); // En multisafe es cancel_url. Tiene sentido que vuelva otra vez a la página del carrito
                                    pasarela.pay();
                                    break; 
    
                                case TRUSTMYTRAVEL.name:
                                    pasarela = new TrustMyTravel(publicToken, totalAmount, description,  name, surname, telephone, email, cart.channel_id, additional_info, money, dominio, history, t, handlePaymentOk, handlePaymentError, uuid, cart)
                                    pasarela.set_url_post(`${urlBasePost}&pasarela=${TRUSTMYTRAVEL.name}`)
                                    pasarela.set_url_ok(url_ok)
                                    pasarela.set_url_ko('')
                                    pasarela.pay();
                                    break;
    
                                case GREENPAY.name:         
                                    pasarela = new Greenpay(tokenTicket, publicToken, totalAmount, description, name, surname, telephone, email, additional_info, money, cart, t)
                                    let res = await pasarela.pay()
                                    setDataGreenpay(res)
                                    break; 
    
                                case STRIPE.name:           
                                    //setUrl_post(`${urlBasePost}&pasarela=${STRIPE.name}`);
                                    //setUrl_post(`${process.env.REACT_APP_baseURL}${process.env.REACT_APP_PREFIX}/pruebaresultStripe`);
                                    pasarela = new Stripe(tokenTicket, publicToken, totalAmount, description, name, surname, telephone, email, additional_info, money, cart, t)
                                    let result = await pasarela.pay()
                                    setDataStripe(result);
                                    if(result.no_error === false){
                                        modalError(t("errorCreatePayment")+result.error_message)
                                    }
                                    setOpenStripe(true)
                                    break;

                                case CREDOMATIC.name:
                                     //setCredomaticInfo(res[0])
                                     setAdditinal_info_credomatic(additional_info)
                                     setOpenCreditCard(true)
                                     break;
                                default: console.log('Otra pasarela no tratada')
                            }
                            // setAdding(false)
                        } else {
                            Sentry.captureException(new Error("CartPage - Carrito vacio"));
                            modalError(t("emptyCart"))
                            setAdding(false)
                        }
                    }               
                
                
                }).catch(e => {
                   Sentry.captureException(new Error("CartPage - errorGetPasarela"));
                   history.push(`/${publicToken}/error/${t("errorGetPasarela")}`)
                }); 
            }
        } else {
            setOpenDataProblemPopup(true);
        }
    }

    let cartItems = cart? [...cart.cartTickets ?? [], ...cart.cartPacketTickets ?? [], ...cart.cartCollaboratorTickets ?? [], ...cart.cartCollaboratorPacketTickets ?? []] : []
    const {name, surname, telephone, email, additionalInformation} = dataBooking
    let habilitarReservar = !tokenTicketDeleted && checked && uuid && totalAmount !== 0 && (name !== "" && surname!== "" && telephone !== "" && email !== "") && ((additionalRequiredInformation && additionalInformation !== undefined && additionalInformation !== "") || !additionalRequiredInformation);
    let carritoBoolean = !tokenTicketDeleted && totalAmount !== 0
    if(!uuid) carritoBoolean = false;

    window.addEventListener('storage', (e) => {
        // When local storage changes, dump the list to
        // the console.
        if(e.key === 'tokenTicket' && e.newValue === null){
            setTokenTicketDeleted(true)
            modalError(t("paidCart"))
        }    
    });

    if(client){
        if(JSON.parse(client.terms) &&  JSON.parse(client.terms).custom && JSON.parse(client.terms).custom[idioma] !== "" && JSON.parse(client.terms).custom[idioma].replace(/\s+/g, '') !== "<p></p>"){
             existsTerms = true;
         }else{
            existsTerms = false;
         } 
    }

    let footer_image = 'https://res.cloudinary.com/marketingpyme/image/upload/w_160,h_45,c_fill/logo_ticando.png'
    if(pasarelaPago) {
        if(pasarelaPago === 'TRUSTMYTRAVEL') {
            footer_image = '/logo_ticando_tmt.png'
        } else if(pasarelaPago === 'CREDOMATIC') {
            footer_image = '/logo_ticando_bac.png'
        }
    }
    return(
        <div className="page-wrapper animated fadeIn">
            <HeaderView publicToken={publicToken}/>            
            <section className="page-header tour-two tour-list destinations-details">
                <div className="container">

                    <div className="row">

                        <div className="col-md-8">
                            <h3 className="destinations-details__title">{t("bookings")}</h3>
                            {
                                !loading && loadedExists &&
                                <div>
                                    <button className="btn btn-underline" onClick={() => handleDeleteCart()} >{t("deleteCart")}</button>
                                </div>
                            }                        
                        </div>

                        {/*<div className="col-md-4">
                            <div className="flow-header-button-wrap">
                                <a className="flow-header-button flow-activities-button test-keep-shopping-link respond-print-hide" href={`/${publicToken}/main`}>
                                    <span className="flow-activities-button-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" id="icon-home" className="icon-home" width="13" height="13" viewBox="0 0 13 13" ng-svg="icon-home">
                                            <path d="M5.63.28l-5,3.54A1.61,1.61,0,0,0,0,5.15v6.52A1.32,1.32,0,0,0,1.34,13H4.46V9.66a2.06,2.06,0,0,1,4.12,0h0V13h3.08A1.31,1.31,0,0,0,13,11.72V5.15a1.66,1.66,0,0,0-.68-1.33L7.41.28A1.57,1.57,0,0,0,5.63.28Z"></path>
                                        </svg>
                                    </span>
                                    <span className="flow-activities-button-text">
                                        {t("continuePurchasing")}
                                    </span>
                                </a>
                            </div>
                        </div>
                        */}

                    </div>

                    <div className="sidebar__single">
                        <div style={{ padding: "0px 25px" }}>       
                            {
                                cartItems.map(cartItem => {
                                    if (!loading && loadedExists) {
                                        return ( 
                                            <CartItem
                                                money={money}
                                                key={cartItem.id}
                                                item={cartItem}
                                                handleDeleteCartItem={handleDeleteItem}
                                                deleting={deleting}
                                                numero={numero}
                                                t={t}
                                            />
                                        )
                                    }
                                })
                            }

                            {
                                !loading && loadedExists && 
                                <CartTotal totalAmount={totalAmount} money={money} t={t} />
                            }
                           
                            <FormDataBooking 
                                adding={adding} 
                                items={items} 
                                carrito={carritoBoolean} 
                                terminos={checked} 
                                datosPersonales={name !== "" && surname!== "" && telephone !== "" && email !== ""} 
                                handleOnClick={sendPayment} 
                                handleChange={handleChange} 
                                values={dataBooking} t={t} 
                                disabled={!habilitarReservar}
                                loading={loading}
                                loadedExists={loadedExists}
                                publicToken={publicToken}
                                client={client}
                                checked={checked}
                                handleChangeCheked={handleChangeCheked}
                                existsTerms={existsTerms}
                                clickTerminos={clickTerminos}
                                additionalRequiredInformation={additionalRequiredInformation}
                                setTelephoneFormat={setTelephoneFormat}
                            />

                            {
                                isCredomatic && additional_info_credomatic &&
                                <Credomatic 
                                  orderid={cart ? cart.token : ""} 
                                  credomaticInfo={credomaticInfo} 
                                  openCreditCard={openCreditCard} 
                                  setOpenCreditCard={setOpenCreditCard} 
                                  amount={totalAmount} 
                                  publicToken={publicToken} 
                                  currency={cart ? cart.money : ''}
                                  additional_info={additional_info_credomatic} 
                                  client={cart ? cart.clientName : ''}
                                />
                            }
                        </div>
                    </div>

                    <div className="row"> 
                        <Dialog onClose={() => {
                            setDataIncorrecto(false);
                            setTelefonoIncorrecto(false);
                            setEmailIncorrecto(false);
                        }} aria-labelledby="customized-dialog-title" open={dataIncorrecto} >
                            <DialogTitle id="customized-dialog-title">{t("incorrect")}</DialogTitle>
                            <DialogContent dividers>
                                <Typography gutterBottom>
                                    <b>{t("the")} {telefonoIncorrecto && "Teléfono"}{telefonoIncorrecto && emailIncorrecto && " y "}{emailIncorrecto && "Email"} {t("formatNotCorrect")}</b>
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    setDataIncorrecto(false);
                                    setTelefonoIncorrecto(false);
                                    setEmailIncorrecto(false);
                                    publish(EventName.OnFinish, null)
                                }} >{t("close")}</button>
                            </DialogActions>
                        </Dialog>

                        <Dialog onClose={closeClickDataProblemHandler} aria-labelledby="customized-dialog-title" open={openGreenPay} >
                            <DialogActions>
                                <button type="button" className="btn btn-primary" onClick={closeClickDataProblemHandler} >{t("close")}</button>
                            </DialogActions>
                        </Dialog>

                        <Dialog onClose={closeClickDataProblemHandler} aria-labelledby="customized-dialog-title" open={openDataProblemPopup} >
                            <DialogTitle id="customized-dialog-title">{t("includeNecessary")}</DialogTitle>
                            <DialogContent dividers>
                                <Typography gutterBottom>
                                    <b>{t("includeNecessarymsg")}</b>
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <button type="button" className="btn btn-primary" onClick={closeClickDataProblemHandler} >{t("close")}</button>
                            </DialogActions>
                        </Dialog>

                        {
                            dataStripe && dataStripe.no_error && 
                                <Dialog open={openStripe} fullWidth>
                                    <Elements options={dataStripe.options} stripe={stripePromise}>
                                        <CheckoutForm publicToken={publicToken} cart={cart} setOpenStripe={handleOpenStripe} url_post={url_post} totalAmount={totalAmount} money={money} t={t}/>
                                    </Elements>                  
                                </Dialog>
                        }
                    </div>
                </div>
            </section>
            <FooterView urlImage={footer_image}/>
        </div>
    );
}

const getTotal = (cart) => {
    if (!cart) return 0

    // total cartTickets
    let totalS = 0
    for (const cartTicket of cart.cartTickets) {
        totalS += cartTicket.ticket.visitas.total
    }

    // total cartCollaboratorTickets
    let totalC = 0
    for (const cartCollaboratorTicket of cart.cartCollaboratorTickets) {
        totalC += cartCollaboratorTicket.ticket.visitas.total
    }

    // total cartPackets
    let totalP = 0
    for (const cartPacketTicket of cart.cartPacketTickets) {
        totalP += cartPacketTicket.packetTicket.visitas.total
    }

    // total cartCollaboratorPackets
    let totalCP = 0
    for (const cartCollaboratorPacketTicket of cart.cartCollaboratorPacketTickets) {
        totalCP += cartCollaboratorPacketTicket.packetTicket.visitas.total
    }

    return Number((totalS + totalC + totalP + totalCP).toFixed(2))
}

