import { primeraLetraMayuscula } from "Modules/Util"

interface BoxAddLessParams {
    name: string
    money: string
    priceUnitary: number 
    prices: any
    index: any
    disabled: boolean
    quantities: any
    description: string
    add: any
    substract: any
    minimum?: any
    minimumPassengerReach?: any
    t?: any
    maxToReserveReach?: any
}


export const BoxAddLess = (props: BoxAddLessParams) => {

    let {name, money, priceUnitary, prices, index, disabled, quantities, description, add, substract, minimum, minimumPassengerReach, t, maxToReserveReach} = props

    return(
        <div className="opcionesExtras">
            <div className="row row-cols-auto text-left align-items-center" style={{padding: 8}}>
                <div className="col-4">
                    <div className="row row-cols-auto">
                        <div className="col">
                            <strong> {primeraLetraMayuscula(name)}</strong>
                        </div>
                    </div>
                    <div className="row row-cols-auto">
                        <div className="col">
                            {priceUnitary.toFixed(2)} {money}
                        </div>
                    </div>
                </div>

                <div style={{color: 'black'}} className="col-3 nopadbado">
                    {prices && prices[index] && prices[index].toFixed(2)} {money}
                </div>

                <div className="col-2 nopadbad">
                    <button className="w-100 btn btn-lg extras" style={{background:'#244c6d'}} value={index} onClick={substract} disabled={disabled} name="delete">
                        <span style={{color:'white'}}>-</span>
                    </button>
                </div>

                <div className="col-1 nopadbado">
                    {quantities && quantities[index]}
                </div>

                <div className="col-2 nopadbad">
                    <button className="w-100 btn btn-lg extras" style={{background:'#244c6d'}} value={index} name="add" onClick={add} disabled={maxToReserveReach}>
                        <span style={{color:'white'}}>+</span>
                    </button>
                </div>
                <div className="row row-cols-auto">
                    {   description && 
                        <p className="col" style={{fontSize: 12}}>
                            {description}
                        </p>
                    }
                    {   minimum !== null && minimum > 0 &&
                        <p className="col" style={!minimumPassengerReach ? {fontSize: 12, color: '#ff9800'} : {fontSize: 12}}>
                            {`- ${t('minToReserve')} ${minimum}`}
                        </p>
                    }   
                
                </div>
            </div>
        </div>
    )
}