import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { PagoIncorrecto } from './components/PagoIncorrecto';
import { PagoCorrecto } from './components/PagoCorrecto';
import { ErrorPage } from './components/ErrorPage';
import {PacketPage} from './Modules/Packet/PacketPage'
import {CartPage} from './Modules/Cart/CartPage'
import {CollaboratorStretchPage} from './Modules/Collaborator/CollaboratorStretchPage'
import { MainPage } from 'Modules/Main/MainPage';
import {StretchPage} from './Modules/Stretch/StretchPage'
import { DefaultPage } from 'components/DefaultPage';
import { ProcesandoPago } from 'Modules/ProcessingPayment/ProcessingPayment';
import { CookiesWarning } from 'components/CookiesWarning';
import { ProcesandoPagoCredomatic } from 'Modules/ProcessingPayment/ProcessingPaymentCredomatic';
import { PagoSinValidar } from 'components/PagoSinValidar';

export const Routes: React.FC = () => {

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/:public' render={(props) => {
                    let matchParamsPublic = String(props.match.params.public)
                    return <Redirect from="/:public" to={`${matchParamsPublic}/main`} />
                }}/>
                <Route exact path='/:public/main' component={MainPage} />
                <Route exact path='/:public/payment/success' component={PagoCorrecto} />
                <Route exact path='/:public/payment/pendingValidation/:code' component={PagoSinValidar} />
                <Route path='/:public/payment/processing/:uuid?/:data?' component={ProcesandoPago} />
                <Route path='/:public/payment/credomatic/:uuid?/' component={ProcesandoPagoCredomatic} />
                <Route exact path='/payment/error' component={PagoIncorrecto} />
                <Route exact path='/:public/cookies' component={CookiesWarning} />
                <Route exact path='/:public/cs/:stretch_id/:operator_id' component={CollaboratorStretchPage} />
                <Route exact path='/:public/trayecto/:stretch_id/:operatioLine_id' component={StretchPage} />
                <Route exact path='/:public/packet/:packet_id/:operationLine_id/' component={PacketPage} />
                <Route exact path='/:public/cp/:packet_id/:operator_id' render={props => <PacketPage {...props} isCollaboratorPacket/>} />
                <Route exact path='/:public/error/:message' component={ErrorPage} />
                <Route exact path='/:public/cart/:data' component={CartPage} />
                <Route exact path='/:public/cart/' component={CartPage} />            
                <Route render={() => {
                    return <DefaultPage/> 
                }}/>
            </Switch>
        </BrowserRouter>
    );
};