import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";

export const DialogNoDate = ({closeClickDateHandler, openDatePopup, t}) => {

    return <Dialog onClose={closeClickDateHandler} aria-labelledby="customized-dialog-title" open={openDatePopup}>
        <DialogTitle id="customized-dialog-title">{t("dateNotAvailable")}</DialogTitle>
        <DialogContent dividers>
            <Typography gutterBottom>
                <b>{t("dateNotAvailablemsg")}</b>
            </Typography>
        </DialogContent>
        <DialogActions>
            <button type="button" className="btn btn-primary" onClick={(e) => closeClickDateHandler(e, 'backdropClick')}>{t("close")}</button>
        </DialogActions>
    </Dialog>
}
