interface Props {
    urlImage?: string
}

export const FooterView = ({urlImage}: Props) => {
    const defaultProps = {
        //urlImage: 'https://res.cloudinary.com/marketingpyme/image/upload/w_160,h_45,c_fill/logo_ticando.png'
        urlImage: 'https://res.cloudinary.com/marketingpyme/image/upload/v1672387517/logo_ticando_1.png'
    }
    const _urlImage = urlImage || defaultProps.urlImage
    
    return (
        <footer className="book-embed-footer">
            <img src={_urlImage} className="img-fluid __web-inspector-hide-shortcut__" alt="TICANDO"/>
        </footer>
    )
}
