import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

interface MyProps {
    handleCloseDialog: any
    card_info: any
    setCardInfo: any
}
  
interface MyState {
handleCloseDialog: any
cvc: string
expiry: string
focus: string
name: string
number: string
}

export const PaymentForm = (props) => {

    const {card_info, setCardInfo} = props

  const handleInputFocus = (e) => {
    setCardInfo({ ...card_info, focus: e.target.name });
  }
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCardInfo({ ...card_info, [name]: value });
  }
  
    return (
      <div id="PaymentForm">
        <div style={{display: 'grid', justifyContent: 'center', marginBottom: '15px'}}>
            <h3 style={{fontSize: '25px'}}>Introduce los datos de tu tarjeta</h3>
        </div>
        <Cards
          cvc={card_info.cvc}
          expiry={card_info.expiry}
          focused={card_info.focus}
          name={card_info.name}
          number={card_info.number}
        />
        <form>
        	<input
                style={{margin: 5, marginTop: 25}}            
                type="tel"
                name="number"
                placeholder="Card Number"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
            />
            <input
                style={{margin: 5}}           
                type="tel"
                name="name"
                placeholder="Name"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
            />
           <input
                style={{margin: 5}}
                type="tel"
                name="expiry"
                placeholder="Valid Thru"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
            />            
            <input
                style={{margin: 5}}
                type="tel"
                name="cvc"
                placeholder="CVC"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
            />
        </form>
      </div>
    );
  }


