import React, {useEffect, useState} from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import OperationLine from '../../models/OperationLine'
import CollaboratorStretch from '../../models/CollaboratorStretch'
import CollaboratorPacket from '../../models/CollaboratorPacket'
import {getAllData, getChannels} from '../../Request/Request'
import {CardStretch, CardCollaboratorStretch, CardPacket} from '../Stretch/CardStretch'
import {FooterView} from '../../components/FooterView'
import { HeaderView } from 'components/HeaderView';
import { getCart } from 'Modules/Cart/Request';
import { Spinner } from 'reactstrap';
import { modalError } from 'Modules/Payment/Request';
import AllData from 'models/AllData';
import { deepFreeze } from 'Modules/Util';
import * as Sentry from "@sentry/react";
import { useInitIdioma } from 'customHooks/useInitIdioma';
import { conditionalAdd, isValidWithChannels } from './Util';
import { BodyError } from 'components/bodyError';

interface MainPageParams {
    public: string;
}

type MainPageProps = RouteComponentProps<MainPageParams>;

export const MainPage: React.FC<MainPageProps> = (props: MainPageProps) => {

    const publicToken = props.match.params.public;
    const history = useHistory();
    const {idioma, t, i18n} = useInitIdioma(publicToken) // Custom Hook
    // const [businessUnit, setBusinessUnit] = useState<BusinessUnit>();
    const [operationLines, setOperationLines] = useState<OperationLine[]>();
    const [collaboratorStretches, setCollaboratorStretches] = useState<CollaboratorStretch[]>();
    const [collaboratorPackets, setCollaboratorPackets] = useState<CollaboratorPacket[]>();
    const [loaded, setLoaded] = useState<boolean>(false)
    const [noError, setnoError] = useState<boolean>(true);
    const [cart, setCart] = useState<any>();
    const [channels, setChannels] = useState<any>(undefined);

    const [busqueda, setBusqueda] = useState<string>('');
    const [data, setData] = useState<AllData>(undefined);
    const [dataOriginal, setDataOriginal] = useState<any>(undefined);
    const [ninguno, setNinguno] = useState<boolean>(false)
    const [screenWidth, setScreenWidth] = useState<any>(undefined)
    const [monedasGreenpay, setMonedasGreenpay] = useState<any>();
    const [currencyCredomatic, setCurrencyCredomatic] = useState<any>()
    //let dataOriginal = undefined

    useEffect(() => {
        if(!screenWidth) {
            setScreenWidth(window.screen.width)
        } else if(screenWidth !== window.screen.width) {
            setScreenWidth(window.screen.width)
        }
    },[screenWidth])

    useEffect(() => {
        i18n.changeLanguage(idioma); 
        
        if (loaded) {
            return 
        }

        if(!channels) {
            getChannels(publicToken).then(canales => {
                if(canales.msg === 'No TMT'){
                    setChannels({msg: 'notmt. Code: 48'})
                    if(canales.currency) setCurrencyCredomatic(canales.currency)
                } else if(canales.msg === 'GREENPAY') {
                    setMonedasGreenpay(canales.monedasDisponibles)
                    setChannels({msg: 'greenpay. Code: 49'})
                } else if(canales.msg === 'No credenciales') {
                    setnoError(false)
                    setChannels({msg: 'No credenciales. Code: 50'})
                } else {
                    setChannels(canales);
                }

                if(!data) {
                    getAllData(publicToken)
                    .then(res => {   

                        res.operationLines.forEach(op => {
                            let newPackets = []
                            op.packets.forEach(packet => {

                                // TODO AllowInPackage código duro
                                /*packet.collaboratorStretches.forEach(cs => {
                                    if(cs.id % 2 === 0) {
                                        cs.allowInPackage = true
                                    } else {
                                        cs.allowInPackage = false
                                    }
                                })
                                packet.stretches.forEach(s => {
                                    if(s.id % 2 !== 0) {
                                        s.allowInPackage = true
                                    } else {
                                        s.allowInPackage = false
                                    }
                                }) */


                                // Restricciones en paquetes con servicios en colaboración
                                let deletePacket = false
                                packet.stretches.forEach(stretch => {
                                   //if(!stretch.allowInPackage) deletePacket = true //TODO
                                })

                                packet.collaboratorStretches.forEach(cs => {
                                    //if(!cs.allowInPackage) deletePacket = true
                                    if(cs.money_id !== packet.money_id) deletePacket = true // Se restringe que la moneda del servicio en colaboración sea igual que la del paquete
                                })

                                if(!deletePacket) newPackets.push(packet)                         
                            })
                            op.packets = newPackets
                        })

                        setData(res)
                        setDataOriginal(res);
                        preprocesamiento(res, res, canales)
                    }).catch(error => {
                        Sentry.captureException(new Error("MainPage - Data"));
                        history.push(`/${publicToken}/error/Ha ocurrido un error - Data`)

                       // modalError('Ha ocurrido un error. Data')
                        setnoError(false)
                    })
                } else {                  
                    preprocesamiento(dataOriginal, data, canales)
                } 
        }).catch(error => {
            Sentry.captureException(new Error("MainPage - Channels"));
            history.push(`/${publicToken}/error/Ha ocurrido un error al hacer la petición - Timeout`)
            // modalError('Ha ocurrido un error. Channels')
            setnoError(false)
        })
    } else {
        if(!data) {
            getAllData(publicToken)
            .then(res => {       
                setData(res)
                setDataOriginal(res);
                preprocesamiento(res, res, channels)
            }).catch(error => {
                Sentry.captureException(new Error("MainPage - Data"));
                history.push(`/${publicToken}/error/Ha ocurrido un error - Data`)
               // modalError('Ha ocurrido un error. Data')
                setnoError(false)
            })
        } else {
            preprocesamiento(dataOriginal, data, channels)
        }
    }
    }, [busqueda]);


    useEffect(() => {
        let uuid;
        try {
            uuid = localStorage.getItem('tokenTicket');
        } catch(e) {
            history.push(`/${publicToken}/cookies`)
        }

        if(uuid) {
            getCart(uuid, publicToken)
            .then((res) => {
                if(res.code && res.code === 433) {                 
                    try {
                        localStorage.removeItem('tokenTicket')
                    } catch(e) {

                    }
                    window.location.reload();
                }
                if (!res.error) {
                    setCart(res);
                }
            })
            .catch(reason => {
                Sentry.captureException(new Error(`MainPage - getCart error`));
                modalError('Ha ocurrido un error: '+reason)
                setnoError(false)
            })
        }
    }, []);

    const preprocesamiento = (originalParam, data: AllData, canales) => {

        let original = deepFreeze(originalParam)

        let collaboratorPackets = [];
        let collaboratorStretches = [];
        let lines = [];
     
        if(original.statusCode === 401){
            Sentry.captureException(new Error("MainPage - Cliente no encontrado"));
            history.push(`/${publicToken}/error/${'Cliente no encontrado'}`)
            return null
        }
         if(original.statusCode){
            setnoError(false)
            Sentry.captureException(new Error("MainPage - Ha ocurrido un error"));
            history.push(`/${publicToken}/error/Ha ocurrido un error`)
            return null
        } 

        if(canales && !canales.hasOwnProperty('msg')){ // TODO y tiene que ser distinto del mensaje que se ponga para greenpay
            let flag = false;
           
            original.operationLines.forEach((op)=>{

            let stretches = []
            let packets = []
                               
                op.stretches.forEach((s)=>{
                    flag = isValidWithChannels(canales, s)
                    if(flag){
                        stretches = conditionalAdd(busqueda, stretches, s)                    
                    }
                })
               
                op.packets.forEach((s)=>{
                    flag = isValidWithChannels(canales, s)
                    if(flag) {
                        packets = conditionalAdd(busqueda, packets, s)      
                    }
                }) 

                let opModificado = JSON.parse(JSON.stringify(data.operationLines.find(o => o.id === op.id)))
                opModificado.stretches = stretches  // Si esto no se pone, el original está bien pero no se filtra y si se pone el original cambia
                opModificado.packets = packets               
                lines = [...lines,opModificado]
            })
            
            original.collaboratorStretches.forEach((cs) => {
                flag = isValidWithChannels(canales, cs)
                if(flag) {
                    collaboratorStretches = conditionalAdd(busqueda, collaboratorStretches, cs)      
                }
               // return collaboratorStretches;
            })
           
            original.collaboratorPackets.forEach((cs) => {
                flag = isValidWithChannels(canales, cs)
                if(flag) {
                    collaboratorPackets = conditionalAdd(busqueda, collaboratorPackets, cs)      
                }
                //return collaboratorPackets;
            }) 

        } else {     
            original.operationLines.forEach((op)=>{

                let stretches = []
                let packets = []
                               
                op.stretches.forEach((s)=>{
                    stretches = conditionalAdd(busqueda, stretches, s)                                           
                })
               
                op.packets.forEach((s)=>{      
                    packets = conditionalAdd(busqueda, packets, s)             
                }) 

                let opModificado = JSON.parse(JSON.stringify(data.operationLines.find(o => o.id === op.id)))
                opModificado.stretches = stretches  // Si esto no se pone, el original está bien pero no se filtra y si se pone el original cambia
                opModificado.packets = packets               
                lines = [...lines,opModificado]
            })
            
            original.collaboratorStretches.forEach((cs)=>{
                collaboratorStretches = conditionalAdd(busqueda, collaboratorStretches, cs)             
            })

            original.collaboratorPackets.forEach((cs) => {
                collaboratorPackets = conditionalAdd(busqueda, collaboratorPackets, cs)                   
            }) 
           // collaboratorStretches = original.collaboratorStretches;
           // collaboratorPackets = original.collaboratorPackets;
        }

        // Compruebo si no todo lo filtrado es vacio
        let vacio = true;
        if(lines.length !== 0) {
            lines.forEach(l => {
                vacio = vacio && l.stretches.length === 0 && l.packets.length === 0              
            })
        } else {
            original.operationLines.forEach(l => {
                vacio = vacio && l.stretches.length === 0 && l.packets.length === 0         
            })
        }

        if(collaboratorStretches.length === 0 && collaboratorPackets.length === 0 && vacio){
            setNinguno(true)
        }else{
            setNinguno(false)
        }
    
        setDataOriginal(original)
        if(lines.length !== 0) {
            setOperationLines(lines)
        } else {
            setOperationLines(original.operationLines)
        }

        setCollaboratorStretches(collaboratorStretches.sort((a, b) => { return a.id - b.id}))
        setCollaboratorPackets(collaboratorPackets.sort((a, b) => { return a.id - b.id}))
        setLoaded(true)
    }

    if(loaded && noError) {
    return (
        <div className="page-wrapper animated fadeIn">
            <HeaderView publicToken={publicToken} isMain={true} busqueda={busqueda} setBusqueda={setBusqueda} setLoaded={setLoaded} clientName={data.clientName}/>

            <section className="page-header">
                <div className="container" style={{display: screenWidth && screenWidth > 778 ? 'grid' : 'block',gridTemplateColumns: '470px 470px'}}>
                    {/*servicios*/}
                    {
                        operationLines && operationLines.map((operationLine) => {
                            return(       
                                !operationLine.disabled_at &&           
                                <React.Fragment key={'stretches_' + operationLine.id}>                                   
                                    {
                                        operationLines && operationLine.stretches.map((stretch) => {
                                            let imagen = 'https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg'
                                            if (stretch.dataweb && stretch.dataweb.image1){
                                                imagen = stretch.dataweb.image1.replace('http://','https://')
                                            }

                                            return (        
                                                !stretch.disableWeb && !stretch.disabled_at &&
                                                <CardStretch
                                                    key={`stretch_${stretch.id}`}
                                                    publicToken={publicToken}
                                                    stretch={stretch}
                                                    imagen={imagen}
                                                    id_operationLine={operationLine.id}
                                                    money={cart && !cart.message ? cart.money : null}
                                                    isGreenpay={channels && channels.hasOwnProperty('msg') && channels.msg.includes('greenpay')}
                                                    monedasGreenpay={monedasGreenpay}
                                                    t={t}
                                                />
                                            );                             
                                        })
                                    }  
                                </React.Fragment>
                            );
                        })
                    }

                    {/*servicios en colaboración*/}
                    {
                        collaboratorStretches && 
                            <>
                                {
                                    collaboratorStretches && collaboratorStretches.map((cStretch) => {
                                        let imagen = 'https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg'
                                        if (cStretch.dataweb && cStretch.dataweb.image1){
                                            imagen = cStretch.dataweb.image1
                                        }

                                        return (
                                            !cStretch.disableWeb && !cStretch.disabled_at && !cStretch.operationLine.disabled_at &&
                                            <CardCollaboratorStretch
                                                key={`${cStretch.id}_${cStretch.operator_id}`}
                                                publicToken={publicToken}
                                                collaboratorStretch={cStretch}
                                                image={imagen}
                                                money={cart && !cart.message ? cart.money : null}
                                                isGreenpay={channels && channels.hasOwnProperty('msg') && channels.msg.includes('greenpay')}
                                                t={t}
                                                monedasGreenpay={monedasGreenpay}
                                            />
                                        );
                                    })
                                }
                            </>
                        }

                    {/*paquetes*/}
                    {
                        operationLines && operationLines.map((operationLine) => {
                            if (!operationLines || !operationLine.packets || operationLine.disabled_at || operationLine.packets.length === 0) {
                                return null
                            }

                            return(
                                <React.Fragment key={'packets_' + operationLine.id}>
                                    {
                                        operationLines && operationLine.packets.map((packet) => {
                                            let imagen = 'https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg'
                                            if (packet.dataweb && packet.dataweb.image1){
                                                imagen = packet.dataweb.image1
                                            }
                                            let stretches = [...packet.stretches, ...packet.collaboratorStretches]
                                            let stretch_disabled = stretches.filter(s => s.disableWeb || s.disabled_at)
                                            if(stretch_disabled.length !== 0) {
                                                return null
                                            }
                                            return (
                                                <CardPacket
                                                    key={`packet_${packet.id}`}
                                                    publicToken={publicToken}
                                                    image={imagen.replace("w_250,h_200,c_fill","w_450,h_350,c_fill")} 
                                                    packet={packet}
                                                    isCollaborator={false}
                                                    money={cart && !cart.message  ? cart.money : null}
                                                    t={t}
                                                    isGreenpay={channels && channels.hasOwnProperty('msg') && channels.msg.includes('greenpay')}
                                                    monedasGreenpay={monedasGreenpay}
                                                />
                                            );
                                        })
                                    }
                                </React.Fragment>
                            );
                        })
                    }

                    {/*paquetes en colaboracion*/}
                    {
                        collaboratorPackets && 
                            <>
                                {
                                    collaboratorPackets.map((cPacket) => {
                                        let imagen = 'https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg'
                                        if (cPacket.dataweb && cPacket.dataweb.image1){
                                            imagen = cPacket.dataweb.image1
                                        }
                                        let stretches = [...cPacket.stretches, ...cPacket.collaboratorStretches]
                                        let stretch_disabled = stretches.filter(s => s.disableWeb || s.disabled_at)
                                        if(stretch_disabled.length !== 0 || cPacket.operationLine.disabled_at) {
                                            return null
                                        }
                                        return (                                                                                    
                                            <CardPacket
                                                key={`${cPacket.id}_${cPacket.operator_id}`}
                                                publicToken={publicToken}
                                                packet={cPacket}
                                                image={imagen.replace("w_250,h_200,c_fill","w_450,h_350,c_fill")}
                                                isCollaborator={true}
                                                money={cart && !cart.message ? cart.money : null}
                                                t={t}
                                                isGreenpay={channels && channels.hasOwnProperty('msg') && channels.msg.includes('greenpay')}
                                                monedasGreenpay={monedasGreenpay}
                                            />
                                        )
                                    })
                                }
                            </>
                    }
                    {
                        ninguno
                        && <h2 style={{textAlign: 'center', fontSize: 'xx-large'}}>Nada que mostrar</h2>
                    }
                </div>
            </section>

            <FooterView/>

        </div>
    )} else if(noError) {
        return (
            <div className="page-wrapper animated fadeIn">
                <HeaderView publicToken={publicToken} isMain={true}/>
                <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
                    <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
                </div> 
                <FooterView/>
            </div>
        )
    } else if(!noError) {
        return (
            <div className="page-wrapper animated fadeIn">
            <HeaderView publicToken={publicToken}/>
            <BodyError message={channels && channels.hasOwnProperty('msg') && channels.msg.includes('50') ? 'Credenciales de la parasera erróneas. Code: 50' : "Error"} listParagraph={[t("contactAdmin"), t("thanksSorry")]}/>
            <FooterView/>
        </div>
        )      
    }
}
