import React, {Component} from "react"
import FullCalendar, {DayCellContentArg, ViewApi } from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import {IHora} from '../models/IHora'
import './CalendarAvailable.css'

interface Props {
    disabledDays
    horasIda
    idioma
    clickDateHandler: any
    dateSelected?
    dateMaxToReserve?
    valueMaxToReserve?
    typeMaxToReserve?
    handleDayRender?(info: {
        view: ViewApi
        date: Date
        allDay?: boolean
        el: HTMLElement
    }): void
}

export default class CalendarAvailable extends Component<Props, object> {

    pRef = React.createRef()

    handleDayCellClassNames = (dayCellContent: DayCellContentArg) => {
        
        const {date, isPast, isToday} = dayCellContent
        const {disabledDays, horasIda, dateMaxToReserve, valueMaxToReserve, typeMaxToReserve} = this.props

        //let dateMaxToReserve = new Date('2023-05-07')
        // let valueMaxToReserve = 3
        //let typeMaxToReserve = 'byDays'

        const disabledDay =  disabledDays?.find(value => value.day === date.getDate() && value.month === date.getMonth() && value.year === date.getFullYear())
    
        date.setHours(23, 59, 59, 999)
        let className = []
               
        if (isPast) {
            className.push('color-isPast')        
        }
        let today = new Date()
        let diaCalculado
        
        // se puede dar los siguientes casos:
        // - typeMaxToReserve === byDate y dateMaxToReserve === una fecha
        // - typeMaxToReserve === byDays y valueMaxToReserver con valor
        // - typeMaxToReserve === byMonth y valueMaxToReserver con valor
        // resto no es necesario controlar
        
        if (typeMaxToReserve === 'byDate') {
            if (dateMaxToReserve) {
                diaCalculado = new Date(dateMaxToReserve)
            }
        } else if (typeMaxToReserve === 'byDays' && valueMaxToReserve) {
            diaCalculado = new Date(today.setDate(today.getDate() + valueMaxToReserve))
        } else if (typeMaxToReserve === 'byMonth' && valueMaxToReserve) {
            diaCalculado = new Date(today.setMonth(today.getMonth() + valueMaxToReserve))
        }
        if (diaCalculado) {
            diaCalculado.setHours(0, 0, 0, 0)

            if(date >= diaCalculado) {
                className.push('color-isMax')
            }
        }

        if(className.length === 0) {
            if (isToday || !isPast) {
                if (disabledDay && disabledDay.hours && disabledDay.hours.length>0){
                    const hoursFiltered = filterHours(horasIda, disabledDay.hours)             
                    if (hoursFiltered.length === horasIda.length) {
                        className.push('color-isDisabled')   
                    } else {
                        className.push('color-isPartialDisabled')  
                    }       
                } else if(disabledDay && disabledDay.hours && disabledDay.hours.length === 0){
                    className.push('color-isDisabled')                    
                }else {
                    className.push('day-cell-enabled-background')
                }          
            }
        }
       
        return className
    }
    
    render() {
     
        const {clickDateHandler, idioma, dateSelected} = this.props
        
        return (
            <>
                <FullCalendar
                    dayCellClassNames={this.handleDayCellClassNames}
                    firstDay={1}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView={"dayGridMonth"}
                    dateClick={clickDateHandler}
                    selectable={true}
                    locale={idioma}
                    buttonText={{ today: idioma === 'es' ? 'Mes actual' : 'Current month' }}
                    contentHeight={'auto'}
                />
            </>
        )
    }
}

/*
  método para filtar las horas deshabilitadas en un IDay.hours ya que se puede dar el caso que alguna se haya eliminado
  en el servicio. Las que no estén en horasIda se eliminan
   */
export const filterHours = (horasIda: IHora[], hoursSelected: IHora[]) => {
    const result = hoursSelected.reduce((previous, hora) => {
        if (horasIda?.find((horaIda) => horaIda.hours === hora.hours && horaIda.minutes === hora.minutes)) {
            previous.push(hora)
        }
        return previous
    }, [])
    return result
}