import {ITMTModal} from '../../models/ITMTModal'
import handlerErrors from './handlerError'
import Swal from 'sweetalert2';
import * as Sentry from "@sentry/react";

const baseURL = process.env.REACT_APP_baseURL
const prefix = process.env.REACT_APP_PREFIX;

// Control de errores hecho, en el push history mete dos veces publicToken
export const getPasarelaPago = async(publicToken: string) => {
    try{
        const request = await fetch(`${baseURL}${prefix}/getPasarelaPago`, {
            headers: { 'Content-Type': "application/json", authorization: `Bearer ${publicToken}` },
            credentials: 'include',
            method: 'GET'
        })
        if(request.status >= 400){
            throw new Error()
        }
        return request.json(); 
    }catch(e){
        throw e;
    }
}

export const getPaymentToken = async (body: string, publicToken: string, pasarelaPago: string, environment?: string) => {
    const request = await fetch(`${baseURL}${prefix}/payment`, {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({body, publicToken: publicToken, pasarelaPago: pasarelaPago, environment: environment}),
        method: 'POST'
    });
    const res = request.json();
    return res;
}

export const getComissionData = async (publicToken: string) => {
    const request = await fetch(`${process.env.REACT_APP_baseURLTicando}/tokenticket/owner`, {
        headers: { 
            'Content-Type': 'application/json',
            "x-provider": "creaticket",
            "Authorization": `Bearer ${publicToken}`
        },
        credentials: 'include',
        method: 'POST'
    });
    const res = request.json();
    return res;
}

export const getAuthenticationTMT = async(channel_id: number, total: number, date, money, publicToken) => {
    let request
    try {
        // Control de errores hecho
        request = await fetch(
            `${baseURL}${prefix}/tmt/authentication?channel_id=${channel_id}&total=${total}&date=${date}&money=${money}&publicToken=${publicToken}`
        );
        if(request.statusCode >= 400){
            throw new Error;
        }
        const res = request.json();
        return res;
    } catch (e) {
        console.log('getAuthenticationTMT - error: ', e)
        throw e;
    }
    return null

}

export const getDominio = async(publicToken: string) => {
    let request
    try {
        // Control de errores hecho
        request = await fetch(
            `${baseURL}${prefix}/getDominio`,{
                headers: { "Content-Type": "application/json", authorization: `Bearer ${publicToken}` },
                credentials: "include",
                method: "GET",
            });
        const res = request.text()
        return res;
    } catch (e) {
        console.log('getDominio - error: ', e)
        throw e;
    }
}

export const getOtherDominio = async(publicToken: string, uuid_dmn: string) => {
    let request
    try {
        // Control de errores hecho
        request = await fetch(
            `${baseURL}${prefix}/url?uuid_dmn=${uuid_dmn}`,{
                headers: { "Content-Type": "application/json", authorization: `Bearer ${publicToken}` },
                credentials: "include",
                method: "GET",
            });
        const res = request.text()
        return res;
    } catch (e) {
        console.log('getDominio - error: ', e)
        throw e;
    }
}

export const EventName = {
    OnFinish: 'onFinish'
}

export type EventName = string

export const subscribe = (eventName: EventName, listener) => {
    document.addEventListener(eventName, listener)
}

export const unsubscribe = (eventName: EventName, listener) => {
    document.removeEventListener(eventName, listener)
}

export const publish = (eventName: EventName, data) => {
    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
}

export const paymentTMTHandler =   (body, url_post: string, url_ok: string, additional_info: string, history: any, publicToken: string, money: string, dominio: string, path_tmt: string, t, handlerOk, handlerError, cart) => {
    // prueba 
    //probar(additional_info);
    let path = path_tmt.substr(1,path_tmt.length);
    path = path.substr(0,path.length-1)
    let message = "";
    let modal; 
    let i = 0;

    console.log('dominio ', dominio)
    if(process.env.REACT_APP_ENVIRONMENT === 'dev'){
         modal = (window as any).tmtPaymentModalSdk({
            path: path, 
            origin: `${process.env.REACT_APP_SUBDOMINIO_DEV}, ${dominio}`,
            environment: "test", // quitar cuando estemos en PROD
            debug: true, // quitar cuando estemos en PROD
            lang: "esES",
            paymentCurrency: money,
            data: body,
        }) as ITMTModal;

    }else if(process.env.REACT_APP_ENVIRONMENT === 'prod'){
         modal = (window as any).tmtPaymentModalSdk({
            path: path, 
            origin: `${process.env.REACT_APP_SUBDOMINIO_PROD}, ${dominio}`,
            environment: "live", // en PROD es live, o si se quita por defecto es live
            lang: "esES",
            paymentCurrency: money,
            data: body,
        }) as ITMTModal;
    }

    let expired = false;
    const tickets = [...cart.cartTickets, ...cart.cartCollaboratorTickets, ...cart.cartPacketTickets, ...cart.cartCollaboratorPacketTickets]
    while(i<tickets.length && !expired){
        if(tickets[i].expire_at !== null){
            if(new Date(tickets[i].expire_at) < new Date()) { // Ha expirado
                expired = true
            }
        }else{
            const treintaMins = new Date(tickets[i].created_at);
            treintaMins.setSeconds(treintaMins.getSeconds()+30*60)
            if(treintaMins < new Date()){
              expired = true
            }
        }
        i++;
    }

    if(expired){
      modal.close()
    }

    modal.on('modal_closed', () => {
        publish(EventName.OnFinish, 'modal_closed')
    })
    
    modal.on("token_error", (data: any) => {
        // Token error, something has ocurred with auth token
        publish(EventName.OnFinish, 'token_error')
        console.log('token_error - data: ', data)
        //handlerError(data)
        modal.closeModal();
        message = handlerErrors(data.message, t)
        //message = `El problema generado desde la pasarela de pago es el siguiente: ${data.message}/`;
        //history.push(`/${publicToken}/error/${message}`); // Send to error page
        Sentry.captureException(new Error(`token_error - ${message}`));
        modalError(message);
    });

    modal.on("booking_error", (data: any) => {
        // Booking error, redirect to error page
        publish(EventName.OnFinish, 'booking_error')
        console.log('booking_error: ', data)
        modal.closeModal();
        //message = `El problema generado desde la pasarela de pago es el siguiente: ${data.message}`;
        message = handlerErrors(data.message, t)
        Sentry.captureException(new Error(`booking_error - ${message}`));
        modalError(message);
       // history.push(`/${publicToken}/error/${message}`); // Send to error page
    });

    modal.on("transaction_logged", (data: any) => {
        // Transaction logged, the transaction has been paid and now we want to redirect to the ticando
        // ticket and fetch to POST payment process
        publish(EventName.OnFinish, 'transaction_logged')
        const payload = JSON.stringify({
            data: data,
            additional_info: additional_info,
            status: data.status,
        });

        modal.closeModal();

        paymentSuccessfullTMT(payload, url_post).then((res:any) => {
            try{
                res = JSON.parse(res)
            }catch(e){
                Sentry.captureException(new Error(`paymentSuccessfullTMT - error parse`));
            }

            if (res.result !== "OK") {
                // console.log('paymentSuccessfullTMT - res: ', res);
                // Reenviamos la petición
                paymentSuccessfullTMT(payload, url_post).then((res:any) => {
                    try{
                        res = JSON.parse(res)
                    }catch(e){
                        Sentry.captureException(new Error(`paymentSuccessfullTMT - error parse`));
                    }
                    if (res.result !== "OK") {
                        // Pensar que hacer
                        //handlerError(res)
                        message = handlerErrors(data.content, t);
                        modalError(message);
                    } else {
                        //sendEmail(additional_info);
                        // window.location.href = url_ok;

                        handlerOk(res)
                    }
                }).catch(e => {throw e});
            } else {
                //sendEmail(additional_info);
                // window.location.href = url_ok;
                handlerOk(res)                
            }
        });
    });

    modal.on("transaction_failed", (data: any) => {
        // Transaction failed, somethig happens in the payment checkout, redirect to error page
        console.log('transaction_failed: ', data)
        publish(EventName.OnFinish, 'transaction_failed')
        message = handlerErrors(data.content, t)
        modal.closeModal();
        // message = `El problema generado es el siguiente: ${data.content}`;
        //history.push(`/${publicToken}/error/${message}`); // Send to error page
        Sentry.captureException(new Error(`transaction_failed - ${message}`));
        modalError(message);
    });

    modal.on("transaction_timeout", (data: any) => {
        // Transaction timeout, somethig happens in the payment checkout, redirect to error page
        console.log('transaction_timeout: ', data)
        publish(EventName.OnFinish, 'transaction_timeout')
        message = handlerErrors(data.content, t)
        modal.closeModal();
        // message = `El tiempo de espera con el banco se ha agotado.`;
        // history.push(`/${publicToken}/error/${message}`); // Send to error page
        Sentry.captureException(new Error(`transaction_timeout - ${message}`));
        modalError(message);
    });

    modal.on("transaction_error", (data: any) => {
        // Transaction error, during the transaction has ocurred some error, redirect to error page
        console.log("transaction error, data: ", data);
        publish(EventName.OnFinish, 'transaction_error')
        message = handlerErrors(data.message, t)
        modal.closeModal();
       // message = `Hemos obtenido un error inesperado desde la pasarela de pago.`;
       // history.push(`/${publicToken}/error/${message}`); // Send to error page
       Sentry.captureException(new Error(`transaction_error - ${message}`));
        modalError(message);
    });

    modal.on("close_window_attempted", (data: any) => {
        // Transaction error, during the transaction has ocurred some error, redirect to error page
        console.log("close_window_attempted error, data: ", data);
        publish(EventName.OnFinish, 'close_window_attempted')
        message = handlerErrors(data.content, t)
        modal.closeModal();
        // history.push(`/${publicToken}/error/${message}`); // Send to error page
        Sentry.captureException(new Error(`close_window_attempted - ${message}`));
        modalError(message);
    });
};

export const paymentSuccessfullTMT = async(payload: string, url_post: string) => {
    try{
        // Control de errores hecho
        const request = await fetch(url_post ,{
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: payload,
            method: "POST",
        });
        return request.text();
    }catch(e){
        console.log('paymentSuccessfullTMT - catch ',e)
        throw e;
    }
}

export const modalError = async(message: string, onIsConfirmed?) => {
    Swal.fire({
        icon: 'error',
        title: 'Error',
        text: message,
        footer: 'Por favor, vuelva a intentarlo de nuevo. \n Gracias y disculpe las molestias'
      }).then((result) =>{
          if(result.isConfirmed && onIsConfirmed){
            onIsConfirmed();
          }
      })
}

export const modalInfo = async(message: string,t: any, onIsConfirmed?) => {
    Swal.fire({
        icon: 'info',
        title: t("info"),
        text: message,
      })
}

export const modalWarning = async(message: string, onIsConfirmed) => {
    Swal.fire({
        icon: 'warning',
        title: 'Atención',
        text: message
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            onIsConfirmed();
        }
    })
}

// Pruebas

export const probar = async(payload: string) => {
    const request = await fetch(`${baseURL}${prefix}/marko` ,{
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: payload,
        method: "POST",
    });
    return request.text();
}

export const sendEmail = async(payload: string) => {

    const request = await fetch(`${prefix}/payment/cartresult` ,{
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: payload,
        method: "POST",
    });
    return request.text();
}


export const resultGreenpayTicando = async(dataEncrypted: string, isValid, url_post: string) => {
    try{
        const request = await fetch(url_post, {
            headers: { 'Content-Type': "application/json"},
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({dataEncrypted: dataEncrypted, isValid: isValid})
        })

        if(request.status >= 400){
            return new Error(request.status+'')
        }
        const res = request.json()
        return res; 
    }catch(e){
        throw e;
    }
}

export const isValidGreenpayOrder = async(dataEncrypted: string, publicToken) => {
    try{
        const request = await fetch(`${baseURL}${prefix}/isValidGreenpayOrder`, {
            headers: { 'Content-Type': "application/json", 'Authorization': `Bearer ${publicToken}`},
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({dataEncrypted: dataEncrypted})
        })

        if(request.status >= 400){
            throw new Error()
        }
        const res = request.json()
        return res; 
    }catch(e){
        throw e;
    }
}

export const getOrderMultiSafePay = async(transactionid: string, publicToken, environment) => {
    try{
        const request = await fetch(`${baseURL}${prefix}/getOrderMultiSafePay`, {
            headers: { 'Content-Type': "application/json", "Authorization": `Bearer ${publicToken}`},
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({transactionid: transactionid, environment: environment})
        })
        if(request.status >= 400){
            throw new Error()
        }
        const res = request.json()
        return res; 
    }catch(e){
        throw e;
    }
}

export const resultStripe = async(publicToken, url_post, payment_intent_id) =>{
    try{
        const request = await fetch(`${baseURL}${prefix}/retrieve-payment-intent`, {
            headers: { 'Content-Type': "application/json", "Authorization": `Bearer ${publicToken}`},
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({url_post: url_post, payment_intent_id: payment_intent_id})
        })
        if(request.status >= 400){
            throw new Error()
        }
        const res = request.json()
        return res; 
    }catch(e){
        throw e;
    }  
}

export const getPasarelaOptions = async(token, keyPasarela) => {
    const requestResult = await fetch(`${baseURL}${prefix}/getPasarelaOptions`,{
        method: 'POST',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' + token
        },
        body: JSON.stringify({keyPasarela})
    });
    return requestResult.json();
}

export const getChannelByIdTicando = async(token, operator_id) => {
    const requestResult = await fetch(`${baseURL}${prefix}/getChannelByIdTicando`,{
        method: 'POST',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' + token
        },
        body: JSON.stringify({operator_id})
    });
    return requestResult.json();
}

export const getMerchant_location_id = async(token, operator_id) => {
    const requestResult = await fetch(`${baseURL}${prefix}/getMerchant_location_id_ByIdTicando`,{
        method: 'POST',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' + token
        },
        body: JSON.stringify({operator_id})
    });
    return requestResult.json(); 
}

export const getMerchantByIdTicando = async(token, operator_id) => {
    const requestResult = await fetch(`${baseURL}${prefix}/getMerchantByIdTicando`,{
        method: 'POST',
        credentials: 'include',
        headers:{
            'Content-type': 'application/json',
            'Authorization':'Bearer ' + token
        },
        body: JSON.stringify({operator_id})
    });
    return requestResult.json();
}

export const resultCredomatic = async(orderid, amount, response, transactionid, avsresponse, cvvresponse, time, key_id, additional_info, hash, url_post: string) => {
    try{
        const request = await fetch(`${baseURL}${prefix}/resultCredomatic`, {
            headers: { 'Content-Type': "application/json"},
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({orderid, amount, response, transactionid, avsresponse, cvvresponse, time, key_id, additional_info, hash, url_post})
        })

        if(request.status >= 400){
            return new Error(request.status+'')
        }
        const res = request.json()
        return res; 
    }catch(e){
        throw e;
    }
}

export const getHash = async(orderid, amount, time, key_id) => {
    try {
        const request = await fetch(`${baseURL}${prefix}/getHashCredomatic`, {
            headers: { 'Content-Type': "application/json"},
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({orderid, amount, time, key_id})
        })
        const res = request.json()
        return res; 
    } catch(e) {
        throw e;
    }
} 