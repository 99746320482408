import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { FooterView } from './FooterView';
import { useInitIdioma } from 'customHooks/useInitIdioma';

interface Props {
    public?: string
    redirect_status?: any //http://localhost:3002/TEGiEYtLDltCpTWCRhsJdMLxLdaBABlTFrDJQvUUhOWYhsZzaSJEdUCoxbpxcbSiTUrkRM/payment/success?payment_intent=pi_3L2sW5AIUUsdAsyQ1SREZARx&payment_intent_client_secret=pi_3L2sW5AIUUsdAsyQ1SREZARx_secret_iwZPrrybjnfgbBAnafsExRDsR&redirect_status=succeeded
}

type CorrectoProps = RouteComponentProps<Props>;

export const PagoCorrecto: React.FC<CorrectoProps> = (props: CorrectoProps) => {
    const publicToken = props.match.params.public;
    const redirect_status = new URLSearchParams(window.location.search).get( "redirect_status");
    const history = useHistory()
    const {t} = useInitIdioma(publicToken) // Custom Hook

    const [data, setData] = useState<any>()

    useEffect(()=> {
       if(!data) {
            try {
                setData({
                    tokenTicket: localStorage.getItem('tokenTicket'),
                    clientName : localStorage.getItem('clientName')
                })
            } catch(e) {
                history.push(`/${publicToken}/cookies`)
            }
        }
        if(data && data.tokenTicket !== null && data.clientName !== null){            
            let url = `https://ticket.ticando.net/?client=${data.clientName}&numero=${data.tokenTicket}`
            setTimeout(()=>{ window.open(url,"_blank");
                                try {
                                    localStorage.removeItem('clientName')
                                    localStorage.removeItem('tokenTicket')
                                } catch(e) {
                                    history.push(`/${publicToken}/cookies`)
                                }
                            },2000)
        }
    },[data])

    return(
        <div className="page-wrapper animated fadeIn">
            <header className="book-embed-nav with-padding respond-print-hide">
                <div className="ben-right">
                    <ul className="ben-left">
                        <li className="test-flow-node-breadcrumb" aria-label="Home" title="Home">
                            <button className="ben-item ben-cart-button fade-in-up" style={{background: 'none', color: '#0a6ece', border: '1px solid #0a6ece', padding: '7px 12px'}} onClick={() => {history.push(`/${publicToken}/main/`)}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grid-3x3-gap-fill" viewBox="0 0 16 16">
                                    <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"/>
                                </svg>
                                <span>
                                {t("allExperiences")}
                                </span>
                            </button>
                        </li>                       
                        <li className="ben-flyout-wrap">
                            <button className="ben-item ben-secure-button fh-green" type="button" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" id="icon-svg-lock" className="icon-svg-lock" width="11" height="14" viewBox="0 0 11.02 14" ng-svg="icon-svg-lock">
                                    <path d="M7.66,3.88a2.15,2.15,0,0,0-4.3,0v2h4.3Z" style={{fill: 'none'}}></path>
                                    <path d="M9.39,5.85v-2a3.88,3.88,0,0,0-7.76,0v2A1.72,1.72,0,0,0,0,7.56v4.73A1.72,1.72,0,0,0,1.71,14h7.6A1.72,1.72,0,0,0,11,12.29V7.56A1.71,1.71,0,0,0,9.39,5.85Zm-6-2a2.15,2.15,0,0,1,4.3,0v2H3.36Z"></path>
                                </svg>
                               {t("securePayment")}
                            </button>
                        </li>
                    </ul> 
                </div>
            </header>

            <section className="page-header tour-two tour-list destinations-details text-center">
                <div className="container">
                    <div className="row">
                        <div className="tour-sidebar">
                            <div className="tour-sidebar__featured pagoCorrecto">
                                <div className="svg-box">
                                    <svg className="circular green-stroke">
                                        <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth={5} strokeMiterlimit={10} />
                                    </svg>
                                    <svg className="checkmark green-stroke">
                                        <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                                            <path className="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53"/>
                                        </g> 
                                    </svg>
                                </div>
                                <h1>{t("correctPayment")}</h1>
                                <div>
                                    {t("correctPaymentmsg")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterView/>
        </div>
    );
}
