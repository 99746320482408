import {Button} from "@material-ui/core";
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import {styled} from "@material-ui/styles";
import React, {ChangeEvent} from "react";
import {FormDataBookingItem} from "./FormDataBookingItem";
import {CheckBoxTerms} from "components/CheckBoxTerms";
import {Spinner} from 'reactstrap'

interface Props {
  items: { name: string, required: boolean, placeholder: string }[]
  values: { [name: string]: string }

  handleOnClick(): void

  handleChange(name: string, value)

  t: any
  disabled: boolean
  datosPersonales: boolean
  carrito: boolean
  terminos: boolean
  adding?: boolean
  loading
  loadedExists
  publicToken
  client
  checked
  handleChangeCheked
  existsTerms
  clickTerminos
  additionalRequiredInformation
  setTelephoneFormat
}

export const FormDataBooking = (props: Props) => {
  const {
    adding,
    items,
    values,
    handleOnClick,
    handleChange,
    t,
    disabled,
    datosPersonales,
    carrito,
    terminos,
    loading,
    loadedExists,
    publicToken,
    client,
    checked,
    handleChangeCheked,
    existsTerms,
    clickTerminos,
    additionalRequiredInformation,
    setTelephoneFormat
  } = props

  const onChange = (event) => {
    handleChange(event.target.name, event.target.value)
  }

  return (
    <>
      <h4 className="tramoSeleccionado">{t("bookingDetails")}</h4>
      <div className="row centrado">
        <div className="col-sm-8">
          {
            items.map(item => (
              <FormDataBookingItem
                key={item.name}
                name={item.name}
                value={values[item.name]}
                placeholder={item.placeholder}
                required={item.required}
                onChange={onChange}
                t={t}
                additionalRequiredInformation={additionalRequiredInformation}
                setTelephoneFormat={setTelephoneFormat}
              />
            ))
          }

          {!loading && loadedExists &&
          <CheckBoxTerms
            publicToken={publicToken}
            client={client}
            checked={checked}
            handleChangeCheked={handleChangeCheked}
            existsTerms={existsTerms}
            clickTerminos={clickTerminos}
          />
          }
        </div>
        <div className="col-sm-4 centrado" style={{/*padding: '50px'*/}}>
          {disabled &&
          <HtmlTooltip
            title={
              <React.Fragment>
                <ul>
                  {!carrito && <li>{t("noCart")}</li>}
                  {!datosPersonales && <li>{t("noData")}</li>}
                  {!terminos && <li>{t("noTerms")}</li>}
                </ul>
              </React.Fragment>
            }
            placement="top"
          >
            <span className="btn btn-huge btn-block btn-large">
                <button
                  style={{textDecorationColor: 'white'}} disabled={disabled}
                  type="submit"
                  className="btn btn-huge sobredimensionado btn-block btn-large btn-primary"
                  onClick={handleOnClick}>{t("book")}
                </button>
            </span>

          </HtmlTooltip>
          }
          {!disabled &&
          <span
            className="btn btn-huge btn-block btn-large">
            {!adding &&
            <Button
              disabled={disabled}
              type="submit"
              className="btn btn-huge sobredimensionado btn-block btn-large btn-primary"
              onClick={handleOnClick}>{t("book")}
            </Button>
            }
            {adding && 
              <Spinner/>
            }
          </span>
          }
        </div>
      </div>
    </>
  )
}

const HtmlTooltip = styled(({className, ...props}: TooltipProps) =>
  (
    <Tooltip {...props} classes={{popper: className}}/>
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'red',
      maxWidth: 500,
      fontSize: '15px',
      border: '1px solid #dadde9',
    },
  }
));