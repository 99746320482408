import { Dialog, DialogActions } from "@material-ui/core";
import { TypeStretch } from '../models/TypeStretch'
import  CalendarAvailable, {filterHours} from './CalendarAvailable'

interface Props {
    selected: ISelected
    handleSetOpenDatePopup(value: boolean): void
    handleDatesSelelected(dateSelected: Date, index: number | string, isStretchCollaborator: boolean): void
    handleDialogActions(): void
    t: any
    idioma: any
}

export const DialogDateSelectView = (props: Props) => {
    const { selected, handleSetOpenDatePopup, handleDatesSelelected, handleDialogActions, t, idioma } = props
   
    const handleDateClick = (date, index, isStretchCollaborator) => {

        // busco si está en disabledDays
        const dateSelected = new Date(date.date ?? date)
        let maxToReserve = false
        if(!index) index = selected.indexSelected
        const result = selected.stretchSelected.disabledDays?.find(day => {
            if (day.day === dateSelected.getDate() &&
                day.month === dateSelected.getMonth() &&
                day.year === dateSelected.getFullYear()
            ) {
                return true
            }
            return false
        })

        // compruebo si todas las horas estan deshabilitadas  
        if (result && allHoursDisabled(selected.stretchSelected.horasIda, result.hours)) {
            handleSetOpenDatePopup(true)
            return
        }

        // Miro si tiene dateMaxToReserve y/o valueMaxToReserve
        if(selected.stretchSelected && selected.stretchSelected.dateMaxToReserve) {
            if(dateSelected >= selected.stretchSelected.dateMaxToReserve) {
                maxToReserve = true
            }              
        }
        
        if(selected.stretchSelected && selected.stretchSelected.valueMaxToReserve && selected.stretchSelected.typeMaxToReserve) {
            let today = new Date()
            let día_calculado;
            if(selected.stretchSelected.typeMaxToReserve === 'byMonth') {
                día_calculado = new Date(today.setMonth(today.getMonth() + selected.stretchSelected.valueMaxToReserve))
            } else if(selected.stretchSelected.typeMaxToReserve === 'byDays') {
                día_calculado = new Date(today.setDate(today.getDate() + selected.stretchSelected.valueMaxToReserve))
            }
        
            if(dateSelected >= día_calculado) {
                maxToReserve = true
            }
        }
        
        // Compruebo si el día es anterior a hoy
        let todaysDate = new Date();
        todaysDate.setHours(0, 0, 0, 0);

        const beforeToday = dateSelected < todaysDate;
        if (beforeToday || maxToReserve) {
            handleSetOpenDatePopup(true)
            return
        }

        handleDatesSelelected(dateSelected, index, isStretchCollaborator)
    }

    return (
        <>
            <Dialog open={!!selected.stretchSelected} fullWidth>
                <p>{selected.stretchSelected && selected.stretchSelected.name}</p>
                <CalendarAvailable
                    disabledDays={selected.stretchSelected?.disabledDays}
                    horasIda={selected.stretchSelected?.horasIda}
                    clickDateHandler={handleDateClick}
                    idioma={idioma}
                    dateMaxToReserve={selected.stretchSelected?.dateMaxToReserve} 
                    valueMaxToReserve={selected.stretchSelected?.valueMaxToReserve}
                    typeMaxToReserve={selected.stretchSelected?.typeMaxToReserve}
                />
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={handleDialogActions}>
                       {t("close")}
                    </button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export const allHoursDisabled = (horasIda, disableDayHours) => {
    const hoursFiltered = filterHours(horasIda, disableDayHours)
    return hoursFiltered.length === horasIda.length
}

export interface ISelected {
    stretchSelected: TypeStretch
    indexSelected: number | string
    isStretchCollaborator: boolean
}
