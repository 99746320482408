import Price from '../models/Price'
import {Tax} from '../models/Tax'
import { Vis } from "models/Vis";
import { useInitIdioma } from 'customHooks/useInitIdioma';
import { BoxAddLess } from './BoxAddLess';

interface Props {
    prices: Price[]
    money: any
    tax: Tax
    visitas: Vis
    quitarVisitantePrices(event: any): void
    addVisitantePrices(event: any): void
    limiteCapacidad?: boolean
    publicToken: any
    isCollaboratorStretch?: boolean
    passengerCategories?: any
    minimumPassengerReach?: any
}

export const PricesView = ({prices, money, tax, visitas, passengerCategories, quitarVisitantePrices, addVisitantePrices, limiteCapacidad, publicToken, isCollaboratorStretch, minimumPassengerReach}: Props) => {

    const {t} = useInitIdioma(publicToken) // Custom Hook 
    
    if (!prices) {
        return null
    }

    if(prices && prices.length === 0) {
        return null
    }

    const reducer = (accumulator, curr) => accumulator + curr;

    let pasajerosTotal = 0;
    if(visitas.vis.length !== 0){
        pasajerosTotal = visitas.vis.slice().reduce(reducer)
    }

    return (
        <>
            <h3 className="preciosHeading">
                {t("prices")}
            </h3>
            {
                prices.map((precio) => {
                    
                    let maxToReserveReach = false

                    const precioViaje = precio.price + precio.price * tax.value
                    const index = prices.indexOf(precio)

                    const passengerCategoryName = precio.passengerCategory ? precio.passengerCategory.name : null

                    const indexCategory = visitas.names.indexOf(passengerCategoryName)
                    const passengersCategory = visitas.vis[indexCategory]

                    if(passengersCategory >= precio.passengerCategory.maxToReserve && precio.passengerCategory.maxToReserve !== null) {
                        maxToReserveReach = true
                    }

                    
                    return (
                        <div className="opciones" key={precio.id}>
                            {
                                precio.passengerCategory.name && precio.price > 0 && ((isCollaboratorStretch && precio.priceNet && precio.priceNet > 0) || !isCollaboratorStretch)  &&                          
                                    <BoxAddLess name={precio.passengerCategory.name} priceUnitary={precioViaje} prices={visitas.price} disabled={visitas.vis[index] === 0} quantities={visitas.vis} description={precio.passengerCategory.description} money={money} index={index} minimum={precio.passengerCategory.minToReserve} minimumPassengerReach={minimumPassengerReach} substract={quitarVisitantePrices} add={addVisitantePrices} t={t} maxToReserveReach={maxToReserveReach}/>                         
                            }
                        </div>
                    )
                })
            }
            {
                limiteCapacidad &&
                <div className="opciones" style={{color:'red'}}>{t("maximumNumber")}{pasajerosTotal}</div>
            }
        </>
    )
}
