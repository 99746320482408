import React, { useState, useEffect, useRef } from 'react';
import SeatPicker from "react-seat-picker";
import Moment from 'moment';
// import '../styles/style.css';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router';
import {addCollaboratorStretchToCart, getCollaboratorStretchesData} from './Request'
import {
    AddCallBackType,
    RemoveCallBackType,
    addVisitanteGlobal,
    generateTokenticket,
    mountPayloadStretch, 
    quitarVisitanteGlobal,
    visOK,
    horasIdaAvailable,
    addExtraGlobal,
    quitarExtraGlobal
} from '../Util'
import {TypeTicket} from '../../models/TypetTicket'
import {HeaderView} from '../../components/HeaderView'
import {Vis} from '../../models/Vis'
import CollaboratorStretch from '../../models/CollaboratorStretch'
import {ISeat} from '../../models/ISeat'
import {IHora} from '../../models/IHora'
import { FooterView } from 'components/FooterView';
import { PricesView } from 'components/PricesView';
import { getChannels } from 'Request/Request';
import {Carousel} from "react-responsive-carousel"
import { InfoWeb } from 'components/InfoWeb'
import { modalError, modalInfo } from 'Modules/Payment/Request';
import Spinner from 'reactstrap/es/Spinner';
import CalendarAvailable from 'components/CalendarAvailable';
import * as Sentry from "@sentry/react";
import { Extras } from 'components/Extras';
import Swal from 'sweetalert2';
import { useInitIdioma } from 'customHooks/useInitIdioma';
import { TourListLoading } from 'components/TourListLoading';
import { CtaView } from 'components/CtaView';
import { DialogNoDate } from 'components/DialogNoDate';
import { OrigenDestino } from 'components/OrigenDestino';
import { SelectIdaVuelta } from 'components/SelectIdaVuelta';
import { BodyError } from 'components/bodyError';

interface ParamsProps {
    stretch_id: string
    operator_id: string
    public: string
}

type Props = RouteComponentProps<ParamsProps>;

export const CollaboratorStretchPage: React.FC<Props> = (props: Props) => {

    const stretchID = Number(props.match.params.stretch_id)
    const operator_id = Number(props.match.params.operator_id)
    const publicToken = props.match.params.public;
    const history = useHistory();
    const {idioma, t, i18n} = useInitIdioma(publicToken) // Custom Hook

    const init: Vis = { names: [], indices: [], vis: [], price: [], base: 0.00, total: 0.00, extras: [], extrasEdit: {}, disabledButtons: false };
    const [passengerCategories, setPassengerCategories] = useState<any>([])

    const CHECKED_CLASSNAME = "btn btn-primary";
    const UNCHECKED_CLASSNAME = "btn btn-outline-primary";

    const [trayecto, setTrayecto] = useState<CollaboratorStretch>();

    const [openDatePopup, setOpenDatePopup] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [visitas, setVisitas] = useState<Vis>(init);
    const [quantityExtras, setQuantityExtras] = useState<any>()
    const [, setDD] = useState<Object[]>([]);
    const [dateClicked, setDateClicked] = useState<string>("");
    const [selectedHour, setSelectedHour] = useState<IHora>();
    const [totalSeats, setTotalSeats] = useState<number>(0); // Número de pasajeros totales
    const [disabled, setDisabled] = useState<boolean>(false);
    const [trayectoIdaVuelta, setTrayectoIdaVuelta] = useState<boolean>(false);
    const [soloIdaClassName, setSoloIdaClassName] = useState<string>(CHECKED_CLASSNAME);
    const [idaVueltaClassName, setIdaVueltaClassName] = useState<string>(UNCHECKED_CLASSNAME);
    const [channels, setChannels] = useState<any>();
    const [disabledCanal, setDisabledCanal] = useState<boolean>(false)
    const [adding, setAdding] = useState<boolean>(undefined);
    const [limiteCapacidad,setLimiteCapacidad] = useState<boolean>(false)
    const [minimumPassengerReach, setMinimumPassengerReach] = useState<boolean>(true)
    const [horasDisponibles, setHorasDisponibles] = useState([])
    const [monedasGreenpay, setMonedasGreenpay] = useState<any>();
    const [prices, setPrices] = useState<any>()
    const [sentidoOrigen, setSentidoOrigen] = useState<boolean>(true) // Determina si el sentido es del origen del trayecto al destino o sale del destino al origen
    const [currencyCredomatic, setCurrencyCredomatic] = useState<string>()
    let description = useRef('');
    let amount = 0;
    let canalSi = false;

    useEffect(() => {
        const querystring = window.location.search

        const params = new URLSearchParams(querystring)
        try {
            if(params.has('uuid_dmn')) {
                let uuid_dmn = params.get('uuid_dmn') 
                localStorage.setItem('uuid_dmn', uuid_dmn)
            } else {
                localStorage.setItem('uuid_dmn', 'dmn')      
            }
        } catch(e) {
            if(publicToken) {
                history.push(`/${publicToken}/cookies`)
            }
        }
    },[])

    useEffect(()=> {
        if(!channels) {
            getChannels(publicToken).then((res) => {
                if(res.msg === 'No TMT'){
                    setChannels({msg: 'notmt. Code: 48'})
                    if(res.currency) setCurrencyCredomatic(res.currency)
                } else if(res.msg === 'GREENPAY') {
                    setMonedasGreenpay(res.monedasDisponibles)
                    setChannels({msg: 'greenpay. Code: 49'})
                } else if(res.msg === 'No credenciales') {
                    setChannels({msg: 'Servicio en mantenimiento. Code: 50'})
                } else {
                    setChannels(res);
                }
    
            }).catch(e => {
                Sentry.captureException(new Error("CollaboratorStretchPage - getChannels"));
                setChannels({msg: 'error. Code: 51'})
            }) 
        }
    },[channels])

    useEffect(() => {
        i18n.changeLanguage(idioma);  
        if (!trayecto) {
            getCollaboratorStretchesData(publicToken).then(result => {
                if(result.statusCode === 401){
                    Sentry.captureException(new Error("CollaboratorStretch - Cliente no encontrado"));
                    history.push(`/${publicToken}/error/${'Cliente no encontrado'}`)
                    return null;
                }
                const trayecto = result.find(value => value.id === Number(stretchID) && value.operator_id === Number(operator_id))
                if(trayecto && trayecto.prices) {
                    setPrices(trayecto.prices)
                }
                if(currencyCredomatic) {
                    if(currencyCredomatic !== result.primaryMoney.alphabeticCode) {
                        setDisabledCanal(true)
                    }
                }
                setTrayecto(trayecto)
            }).catch(e => {
                Sentry.captureException(new Error("CollaboratorStretch - getCollaboratorStretchesData"));
                history.push(`/${publicToken}/error/Ha ocurrido un error - getCollaboratorStretchesData`)
            })

        } else if (trayecto) {
            description.current = trayecto.name;
            let auxDD: Object[] = [];
            if (trayecto.disabledDays) {
                trayecto.disabledDays.forEach((disabledDay) => {
                    let str = '{ "title": "No Disponible", ';
                    const date = new Date(disabledDay.year, disabledDay.month, disabledDay.day);
                    str += '"date": "' + Moment(date).format("YYYY-MM-DD") + '", ';
                    str += '"constraint": "noDisponible", ';
                    str += '"backgroundColor": "#ff9f89", '
                    str += '"display": "background"';
                    str += '}';
                    let strjs = JSON.parse(str);
                    auxDD.push(strjs);
                });

                setDD(auxDD);
            }
            if (prices) {
                setDisabled(prices.length === 0);
                let auxVis: number[] = [];
                let auxPrice: number[] = [];
                let auxPriceNet: number[] = [];
                let auxNames: string[] = [];
                const indices: number[] = []
                prices.forEach((precio) => {
                    auxVis.push(0);
                    auxPrice.push(0.00);
                    auxPriceNet.push(0.00);
                    auxNames.push(precio.passengerCategory.name);
                    indices.push(precio.passengerCategory.id)
                    if(precio.passengerCategory.minToReserve && precio.price > 0) setMinimumPassengerReach(false)
                });

                let auxQuantityExtras: number[] = []
                let auxPriceExtras: number[] = []
                let auxNamesExtras: string[] = []
                let auxTaxExtras: number[] = []
                trayecto.extras.forEach((extra) => {
                    auxQuantityExtras.push(0)
                    auxPriceExtras.push(0.00)
                    auxTaxExtras.push(extra.tax.value)
                    auxNamesExtras.push(extra.name)
                })

                setVisitas({
                    names: auxNames,
                    indices,
                    vis: auxVis,
                    price: auxPrice,
                    priceNet: auxPriceNet,
                    base: 0.00,
                    total: 0.00,
                    totalNet: 0.00,
                    extras: [] ,extrasEdit: {
                        names: auxNamesExtras,
                        prices: auxPriceExtras,
                        taxes: auxTaxExtras,
                        quantityExtras: auxQuantityExtras
                     },
                    disabledButtons: false
                });

                setTotalSeats(0);
            } else {
                setDisabled(true);
            }
        }

        if(trayecto) {
            if(channels && !channels.hasOwnProperty('msg')) { 
                channels.forEach(c => {  
                    if(c.currencies === trayecto.primaryMoney.alphabeticCode) {
                        canalSi = true;
                    }
                })
                setDisabledCanal(!canalSi)
            } else if(channels && channels.msg && channels.msg.includes('greenpay') && trayecto) {
                let moneda = trayecto.primaryMoney.alphabeticCode
                if(monedasGreenpay.includes(moneda)){
                    canalSi = true;
                }
                setDisabledCanal(!canalSi)
            } else if(currencyCredomatic && trayecto) {
                if(currencyCredomatic !== trayecto.primaryMoney.alphabeticCode) {
                    setDisabledCanal(true)
                }
            }
        }
    }, [trayecto, channels, quantityExtras]);

    useEffect(() => {
        if(trayecto){
            const horas = trayecto.horasIda;
            if (horas && horas.length !== 0 && !selectedHour) {
                setSelectedHour(horas[0])
                setHorasDisponibles(horas)
            } 
        }
    }, [])

    useEffect(() => {
        if(trayecto){
            const horas = trayecto.horasIda;
            if (horas && horas.length !== 0 && !selectedHour) {
                setSelectedHour(horas[0])
            } 
        }
    }, [horasDisponibles])

    useEffect(() => {
        if(!trayectoIdaVuelta) { // El trayecto es solo ida
            if(trayecto && trayecto.prices) {
                setPrices(trayecto.prices)
            } 
        } else { // el trayecto es ida y vuelta
            if(trayecto && trayecto.vueltaPrices) {
                setPrices(trayecto.vueltaPrices)
            } 
        }
    }, [trayectoIdaVuelta, trayecto])

    const clickDateHandler = (arg: any) => {
        const date = arg.date;
        // Solamente el día clicado en cada momento será el día seleccionado con fondo azul
        const days = document.querySelectorAll(".color-selected");
        days.forEach(function(day) {
            day.classList.remove("color-selected");
        });
        arg.dayEl.classList.add("color-selected");
        let pertenece: boolean = false;
        let maxToReserve: boolean = false

        if(trayecto){
            if(trayecto.disabledDays){
                    // miro si el día clicado está en deshabilitados
                    const position = trayecto.disabledDays.findIndex(disabledDay =>
                        disabledDay.day === date.getDate() && disabledDay.month === date.getMonth() && disabledDay.year === date.getFullYear()
                    )
                    if (position === -1) { // no está en disabledDays, el día está habilitado
                        if (trayecto.tramoHora && trayecto.horasIda) {
                            setHorasDisponibles(trayecto.horasIda)
                        }
                        if(!selectedHour && trayecto.horasIda) {
                            setSelectedHour(trayecto.horasIda[0])
                        }
                    } else { // el día está en disabledDays. puede ocurrir varias condiciones
                        // busco el día deshabilitado
                        const dayDisabled = trayecto.disabledDays[position]
    
                        // tramoHora: si hours.length === 0 all dia deshabilitado, si hours.length >0 tiene horas deshabilitadas
                        if (!trayecto.tramoHora) {// no es tramoHora, el día está deshabilitado
                            pertenece = true
                        } else {
                            if (dayDisabled.hours.length === 0) { // todas las horas deshabilitadas
                                setHorasDisponibles([])
                                pertenece = true
                            } else {
                                const horasIda = trayecto.horasIda || []
                                const horasIdaReduced = horasIdaAvailable(horasIda, dayDisabled)
                                setHorasDisponibles(horasIdaReduced)
                                if (horasIdaReduced.length === 0) {
                                    pertenece = true
                                } else {
                                    let incluido = []
                                    if(selectedHour) incluido = horasIdaReduced.filter(hora => hora.id === selectedHour.id)
                                    if(!selectedHour || incluido.length === 0) setSelectedHour(horasIdaReduced[0])
                                }
                            }
                        }
                    }                   
            } else {
                if (trayecto.tramoHora && trayecto.horasIda) {
                    setHorasDisponibles(trayecto.horasIda)
                    if(!selectedHour) setSelectedHour(trayecto.horasIda[0])
                }
            }    
            
            // Miro si tiene dateMaxToReserve y/o valueMaxToReserve
            if(trayecto.dateMaxToReserve) {
                if(date >= trayecto.dateMaxToReserve) {
                    maxToReserve = true
                }              
            }

            if(trayecto.valueMaxToReserve && trayecto.typeMaxToReserve) {
                let today = new Date()
                let día_calculado;
                if(trayecto.typeMaxToReserve === 'byMonth') {
                    día_calculado = new Date(today.setMonth(today.getMonth() + trayecto.valueMaxToReserve))
                } else if(trayecto.typeMaxToReserve === 'byDays') {
                    día_calculado = new Date(today.setDate(today.getDate() + trayecto.valueMaxToReserve))
                }

                if(date >= día_calculado) {
                    maxToReserve = true
                }
            }
        }
        let todaysDate = new Date()
        todaysDate.setHours(0, 0, 0, 0)

        let dateParsed = new Date(date)
        const beforeToday = dateParsed < todaysDate
        if (!pertenece && !beforeToday && !maxToReserve) {
            //"2020-08-04T14:58:01.415Z"
            setDateClicked(Moment(date).format())
        } else {
            setOpenDatePopup(true)
        }
        /*if (trayecto && trayecto.disabledDays) {
            trayecto.disabledDays.forEach((disabledDay) => {
                const dateDisabledDay = Moment(new Date(disabledDay.year, disabledDay.month, disabledDay.day)).format("YYYY-MM-DD");
                if (date === dateDisabledDay) pertenece = true;
            });
        }
        let todaysDate = new Date();
        todaysDate.setHours(0, 0, 0, 0);

        let dateParsed = new Date(date);
        const beforeToday = dateParsed < todaysDate;
        if (!pertenece && !beforeToday) {
            //"2020-08-04T14:58:01.415Z"
            setDateClicked(Moment(date).format());
        } else {
            setOpenDatePopup(true);
        } */
    }

    const closeClickDateHandler = () => {
        setOpenDatePopup(false);
    };

    const addExtras = (event: any) => {
        const index = event.currentTarget.value
        if(trayecto && index) {
            const {auxVis,limite} = addExtraGlobal(visitas, trayecto, index)
            if(auxVis !== null) setVisitas(auxVis)
        }
    }

    const quitarExtras = (event: any) => {
        const index = event.currentTarget.value
        if(trayecto && index) {
            const {auxVis,limite} = quitarExtraGlobal(visitas, trayecto, index)
            if(auxVis !== null) setVisitas(auxVis)
        }
    }

    const addPassenger = (event: any) => {
        const index = event.currentTarget.value;
        if(index) {
            const categoryToAdd = prices[index]
            const categorySearched = passengerCategories.find(c => c.id === categoryToAdd.passengerCategory.id)
            if(categorySearched) { // Ya está la categoría en nuestro array, sumamos +1 a quantity
                categorySearched.quantity++;
            } else { // Todavía no tenemos esa categoría en el array, y añadimos el objeto con todos los campos necesarios
                const aux = {
                    id: categoryToAdd.passengerCategory.id,
                    name: categoryToAdd.passengerCategory.name,
                    price: categoryToAdd.price,
                    priceNet: categoryToAdd.priceNet,
                    quantity: 1
                }
                setPassengerCategories([...passengerCategories, aux])
            }

            

            let auxTot = totalSeats;
            auxTot++;
            setTotalSeats(auxTot);

            let limiteCapacidad = false;
            if(trayecto.capacity !== undefined && trayecto.capacity !== null) {
                if(totalSeats >= trayecto.capacity) {
                    limiteCapacidad = true;
                }
            }
            setLimiteCapacidad(limiteCapacidad)
        }
    }

    const removePassenger = (event: any) => {
        const index = event.currentTarget.value;
        if(index) {
            const categoryToRemove = prices[index]
            const categorySearched = passengerCategories.find(c => c.id === categoryToRemove.passengerCategory.id)
            if(categorySearched) { // Ya está la categoría en nuestro array
                if (categorySearched.quantity === 0) { // Sólo queda uno de esa categoría, así que borramos la categoría del array
                    setPassengerCategories(passengerCategories.splice(passengerCategories.indexOf(categorySearched)))
                } else { // Quedan varios pasajeros de esa categoría, así que restamos uno a la cantidad
                    categorySearched.quantity--;
                }
            } else { // Todavía no tenemos esa categoría en el array, y añadimos el objeto con todos los campos necesarios
                console.log('error')
            }
            let auxTot = totalSeats;
            auxTot--;
            setTotalSeats(auxTot);
        }
        console.log('passengerCategories ', passengerCategories)
    }

    const addVisitantePrices = (event: any) => {
        const index = event.currentTarget.value;
        if (trayecto && index) {
            const {auxVis, limite} = addVisitanteGlobal(visitas, trayecto, index, prices);
            prices.forEach(price => {
                if(price.passengerCategory.minToReserve && price.price > 0) {
                    const name = price.passengerCategory.name
                    const index = auxVis.names.indexOf(name)
                    const nReserve = auxVis.vis[index]
                    if(nReserve >= price.passengerCategory.minToReserve){
                        setMinimumPassengerReach(true)
                    } else {
                        setMinimumPassengerReach(false)
                    }
                }
            })
            if(auxVis !== null) setVisitas(auxVis);
            setLimiteCapacidad(limite)
            let auxTot = totalSeats;
            auxTot++;
            setTotalSeats(auxTot);
        }
    };
    const addVisitanteVueltaPrices = (event: any) => {
        const index = event.currentTarget.value;
        if (trayecto && index) {
            const {auxVis} = addVisitanteGlobal(visitas, trayecto, index, trayecto.vueltaPrices);           
            if (auxVis !== null) setVisitas(auxVis);
            let auxTot = totalSeats;
            auxTot++;
            setTotalSeats(auxTot);
        }
    };

    const quitarVisitantePrices = (event: any) => {
        const index = event.currentTarget.value;
        if (trayecto && index) {
            const auxVis = quitarVisitanteGlobal(visitas, trayecto, index, prices);
            prices.forEach(price => {
                if(price.passengerCategory.minToReserve && price.price > 0) {
                    const name = price.passengerCategory.name
                    const index = auxVis.names.indexOf(name)
                    const nReserve = auxVis.vis[index]
                    if(nReserve >= price.passengerCategory.minToReserve){
                        setMinimumPassengerReach(true)
                    } else {
                        setMinimumPassengerReach(false)
                    }
                }
            })
            if (auxVis !== null) setVisitas(auxVis);
            setLimiteCapacidad(false)
            let auxTot = totalSeats - 1;
            setTotalSeats(auxTot);
        }
    };

    const handleChangeOpcionVuelta = (event: any) => {
        setTrayectoIdaVuelta(!trayectoIdaVuelta)
    }

   const handleSentidoTrayecto = (e) => {
        setSentidoOrigen(!sentidoOrigen)
   }

    const quitarVisitanteVueltaPrices = (event: any) => {
        const index = event.currentTarget.value;
        if (trayecto && index) {
            const auxVis = quitarVisitanteGlobal(visitas, trayecto, index, trayecto.vueltaPrices);
            if (auxVis !== null) setVisitas(auxVis);

            let auxTot = totalSeats - 1;
            setTotalSeats(auxTot);
        }
    };

    const horaChangeHandler = (event: any) => {

        const id = event.currentTarget.value 
        if(id !== undefined && trayecto) {
            let hora = trayecto.horasIda.filter(hora => hora.id === +id)
            setSelectedHour(hora[0])
        }
        /*const index = event.currentTarget.value;
        if (index !== undefined && trayecto) {
            setSelectedHour(trayecto.horasIda[index]);
        } */
    };

    const addSeatCallback = async ({ row, number, id }: ISeat, addCb: AddCallBackType) => {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1500));
        const newTooltip = 'Seleccionado';
        addCb(row, number, id, newTooltip);
        setLoading(false);
    };

    const removeSeatCallback = async ({ row, number, id }: ISeat, removeCb: RemoveCallBackType) => {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1500));
        const newTooltip = null;
        removeCb(row, number, newTooltip);
        setLoading(false);
    };

    const trayectoIdaVueltaClickHandler = (event: any) => {
        setTrayectoIdaVuelta(true);
        setSoloIdaClassName(UNCHECKED_CLASSNAME);
        setIdaVueltaClassName(CHECKED_CLASSNAME);
    }

    const trayectoSoloIdaClickHandler = (event: any) => {
        setTrayectoIdaVuelta(false);
        setSoloIdaClassName(CHECKED_CLASSNAME);
        setIdaVueltaClassName(UNCHECKED_CLASSNAME);
    }

    const addCart = () => {
        if (!dateClicked) {
            setOpenDatePopup(true);
        } else {
            setAdding(true)
            amount = (+ visitas.total.toFixed(2));
            // TODO
            if (trayecto) {
                let tokenTicket;
                try {
                    tokenTicket = localStorage.getItem('tokenTicket');
                    if (!tokenTicket) {
                        tokenTicket = generateTokenticket(20);
                        localStorage.setItem('tokenTicket', tokenTicket);
                    }
                } catch(e) {
                    if(publicToken) {
                        history.push(`/${publicToken}/cookies`)
                    }
                }
                
                 mountPayloadStretch(
                    trayecto,
                    dateClicked, 
                    selectedHour,
                    visitas,
                    'collaboratorStretch',
                    publicToken,
                    trayectoIdaVuelta,
                    sentidoOrigen,
                    prices,
                    channels
                ).then((ticket: any) => {
                    ticket.type = TypeTicket.CollaboratorStretch
                    ticket.operator_id = trayecto.operator_id
                    ticket.additional = additional
                    ticket.visitas = visitas 
                    ticket.prices = prices
                    ticket.tax = trayecto.tax
                    ticket.operatorName = trayecto.operatorName
    
                    let uuid;
                    try {
                        uuid = localStorage.getItem('tokenTicket');
                    } catch(e) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Es necesario activar las cookies de terceros para poder realizar la reserva.',
                            footer: `Ayuda: En Chrome dirígete a chrome://settings/cookies para permitirlo.`
                          }) 
                    }
    
                    addCollaboratorStretchToCart(ticket, publicToken).then((res) => {
                        setAdding(false)
                        let message: string = "";
                        let info = false;
                        switch (res.code) {
                            case 200: 
                                if (!uuid) {
                                    try {
                                        localStorage.setItem('tokenTicket', res.payload.token)
                                    } catch(e) {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Error',
                                            text: 'Es necesario activar las cookies de terceros para poder realizar la reserva.',
                                            footer: `Ayuda: En Chrome dirígete a chrome://settings/cookies para permitirlo.`
                                          }) 
                                    }
                                }
                                history.push({
                                    pathname: `/${publicToken}/cart/`
                                })
                                break;
                            case 400:
                                message = t("tokenNoExists"); // El token no existe.
                                break
                            case 404:
                                message = t("stretchInCart") //'Ya tiene agregada esa experiencia al carrito, si quiere modificarla tendrá que eliminarla primero del carrito.'
                                break
                            case 410:
                                message = t("errorCreateTicket") //"Error al generar el ticket."
                                break
                            case 412:
                                message = t("obtainError") //"Hemos obtenido un error."
                                break
                            case 413:
                                info = true;
                                message = `${ticket.tramoHora ? t("noDisponibility") : 'En este momento no tenemos disponibilidad para el día seleccionado.'}  ${t("thenDisponibility")}`  //t("notEnoughCapacity") //"No hay aforo suficiente."
                                break
                            case 414:
                                info = true;
                                message =  t("serviceNotAvailable") //"El servicio que quiere reservar no está disponible."
                                break
                            case 415:
                                info = true;
                                message = t("dateDisabled") //"El servicio está deshabilitado para esa fecha."
                                break
                            case 416:
                                info = true;
                                message = t("hourDisabled") //"El servicio está deshabilitado para esa hora."
                                break
                            case 500:
                                message = t("serverError") //"Error interno del servidor."
                                break
                            default:
                                message = t("Sorry, we got an unexpected error.") //"Lo siento, hemos obtenido un error inesperado."
                                break                
                        }
                        if(message !== ""){
                            if(info) {
                                modalInfo(message, t)
                            } else {
                                modalError(message)
                            }     
                        }
                        // Comprobamos si es un número
                        /*if ((/^[0-9]*$/).test(res)) {
                            const statusCode: number = +res;
                            let message: string = "";
                            switch (statusCode) {
                                case 400:
                                    message = "El token no existe.";
                                    break;
                                case 404:
                                    message = 'Ya tiene agregada esa experiencia al carrito, si quiere modificarla tendrá que eliminarla primero del carrito.';
                                    break;
                                case 410:
                                    message = "Error al generar el ticket.";
                                    break;
                                case 412:
                                    message = "Hemos obtenido un error.";
                                    break;
                                case 413:
                                    message = "No hay aforo suficiente.";
                                    break;
                                case 414:
                                    message = "El servicio que quiere reservar no está disponible.";
                                    break;
                                case 415:
                                    message = "El servicio está deshabilitado para esa fecha.";
                                    break;
                                case 416:
                                    message = "El servicio está deshabilitado para esa hora.";
                                    break;
                                case 500:
                                    message = "Error interno del servidor.";
                                    break;
                                default:
                                    message = "Lo siento, hemos obtenido un error inesperado.";
                                    break;
                            }
                            Sentry.captureException(new Error(`CollaboratorStretch - ${message}`));
                            history.push(`${publicToken}/error/${message}/`);
                        } else { // En caso de no ser un número, es la url
                            if (!uuid) {
                                localStorage.setItem('tokenTicket', res.uuid);
                            }
                            history.push({
                                pathname: `/${publicToken}/cart/`,
                                search: publicToken
                            })
                        }*/
                    }).catch(e => {
                        setAdding(false)
                        Sentry.captureException(new Error(`CollaboratorStretch - Añadir`));
                        modalError(t("errorAddCart"))
                    });
                });

                const additional = {
                    stretchName: trayecto.name,
                    stretchDescription: trayecto.description,
                    tokenticket: tokenTicket,
                    description: description
                }
            }
        }
    };

    let disableAddCart = true
    if (trayecto) {
        disableAddCart = !visOK(visitas) || dateClicked === ''
    }

    const images = []
    if (trayecto && trayecto.dataweb) {

        for(let index in trayecto.dataweb) {
            if(index.startsWith('image')) {
                const temp = {
                    original: trayecto.dataweb[index].replace('http://','https://'),
                    thumbnail: trayecto.dataweb[index].replace('http://','https://')
                }
                images.push(temp)
            }
        }
        /*for (let i=1; i<=15; i++) {
            if (trayecto.dataweb[`image${i}`]) {
                if(trayecto.dataweb[`image${i}`] !== " "){
                    const temp = {
                        original: trayecto.dataweb[`image${i}`].replace('http://','https://'),
                        thumbnail: trayecto.dataweb[`image${i}`].replace('http://','https://')
                    }
                    images.push(temp)
                }               
            }
        }*/
    }
    if(images.length === 0){
        images.push(
            {
                original: "https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg",
                thumbnail: "https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg"
            })
    }
    return (
        <div className="page-wrapper animated fadeIn">
            <HeaderView publicToken={publicToken}/>
            <section className="page-header tour-two tour-list destinations-details">
                <div className="container">
                    <div className="row">

                        {
                            ((!trayecto && trayecto !== null) || !channels) &&
                            <div style={{top:'50%',left:'50%,',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>             
                                <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
                            </div> 
                        }

                        {
                            trayecto && (trayecto.disableWeb || trayecto.disabled_at || trayecto.operationLine.disabled_at) && 
                            <BodyError message={t("serviceNotAvailable")} listParagraph={[t("thanksSorry")]}/>
                        }

                        {
                            channels && channels.hasOwnProperty('msg') && channels.msg.includes('5') &&
                            <BodyError message={channels.msg ? channels.msg : 'error'} listParagraph={[t("requestChannelsErrormsg") ,t("thanksSorry")]}/>    
                        }

                        {
                            trayecto && !trayecto.disableWeb && !trayecto.disabled_at && !trayecto.operationLine.disabled_at && channels && ((channels.hasOwnProperty('msg') && !channels.msg.includes('5')) || !channels.hasOwnProperty('msg')) &&                           
                            <>
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-8">
                                    {
                                        disabledCanal && channels.msg && !channels.msg.includes('greenpay') && !currencyCredomatic &&
                                        <div style={{backgroundColor:'red', color: 'white'}}>
                                            {t("noMoneyTMT")}
                                        </div>
                                    }   
                                    {
                                        disabledCanal && channels.msg && channels.msg.includes('greenpay') && !currencyCredomatic &&
                                        <div style={{backgroundColor:'red', color: 'white'}}>
                                            {t("noMoneyGreenpay")}
                                        </div>
                                    }     
                                    {
                                        disabledCanal && currencyCredomatic && currencyCredomatic !== trayecto.primaryMoney.alphabeticCode &&
                                        <div style={{backgroundColor:'red', color: 'white'}}>
                                            {t("noMoneyCredomatic")}
                                        </div>
                                    }                            
                                    <div className="destinations-details__content">
                                        <h3 className="destinations-details__title">{trayecto.name}</h3>
                                        <Carousel autoPlay infiniteLoop showStatus={false}>
                                            {
                                                images.map(image => {
                                                    return(
                                                        <div key={image.original}>
                                                            <img src={image.original.replace("w_250,h_200,c_fill","c_fill").replace("w_450,h_350","")} alt=''/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Carousel>
                                        {                                                 
                                            <InfoWeb trayecto = {trayecto} orden={1} publicToken={publicToken}/>
                                        }                                  
                                    </div>
                                </div>
                        
                                <div className="col-xs-12 col-sm-12 col-md-5 col-lg-4">
                                    <div className="tour-sidebar">
                                        <div className="tour-sidebar__featured">
                                            <CtaView t={t} />
                                            <div className="fechas">
                                                <CalendarAvailable 
                                                    disabledDays={trayecto.disabledDays} 
                                                    horasIda={trayecto.horasIda} 
                                                    clickDateHandler={clickDateHandler} 
                                                    idioma={idioma}
                                                    dateMaxToReserve={trayecto.dateMaxToReserve} 
                                                    valueMaxToReserve={trayecto.valueMaxToReserve}
                                                    typeMaxToReserve={trayecto.typeMaxToReserve}
                                                />
                                                
                                                {
                                                    dateClicked !== "" &&
                                                    <p className="lead">
                                                        <b>{t("dateSelected")}:</b> {Moment(dateClicked).format("DD/MM/YYYY")}
                                                    </p>
                                                }
                                            </div>
                                            <DialogNoDate openDatePopup={openDatePopup} closeClickDateHandler={closeClickDateHandler} t={t}/>

                                            {
                                            dateClicked && trayecto && trayecto.tramoHora && horasDisponibles && horasDisponibles.length > 0 &&
                                                <div className="horas">
                                                    <h3>{t("departureTime")}</h3>
                                                    

                                                    <select value={selectedHour.id} className="custom-select" id="hourSelect" onChange={horaChangeHandler} >
                                                        {
                                                            horasDisponibles.map((hora) => {
                                                                const horaString = ((hora.hours.toString().length === 1) ? "0" : "") + hora.hours.toString() + hora.minutes.toString();
                                                                const horaSalida = Moment(horaString, "HHmm").format('HH:mm');
                                                                return (
                                                                    <option value={hora.id} key={hora.id}>
                                                                        {horaSalida}
                                                                    </option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            }

                                            {
                                                trayecto.vuelta &&
                                                <div className="opciones">
                                                    <br/>
                                                    <div className="row row-cols-auto text-left align-items-center">
                                                            <SelectIdaVuelta prices={trayecto.prices} vueltaPrices={trayecto.vueltaPrices} totalSeats={totalSeats} handleChangeOpcionVuelta={handleChangeOpcionVuelta} trayectoIdaVuelta={trayectoIdaVuelta}/>
                                                        {
                                                            trayecto.origin && trayecto.destination &&
                                                            <OrigenDestino sentidoOrigen={sentidoOrigen} trayecto={trayecto} handleSentidoTrayecto={handleSentidoTrayecto}/>
                                                        }
                                                    </div>   
                                                </div>
                                            }
                                            {
                                                trayecto.hoponHopoff &&
                                                <div className="opciones">
                                                    <div>
                                                        <p className="outline-primary" >HoponHopff</p>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                disabled &&
                                                <div>
                                                    {t("experienceNotAvailablemsg")}
                                                </div>
                                            }

                                            <PricesView
                                                prices={prices}
                                                money={trayecto.primaryMoney.alphabeticCode}
                                                tax={trayecto.tax}
                                                passengerCategories={passengerCategories}
                                                visitas={visitas}
                                                addVisitantePrices={addVisitantePrices}
                                                quitarVisitantePrices={quitarVisitantePrices}
                                                //addVisitantePrices={addPassenger}
                                                //quitarVisitantePrices={removePassenger}
                                                limiteCapacidad={limiteCapacidad}
                                                publicToken={publicToken}
                                                isCollaboratorStretch
                                                minimumPassengerReach={minimumPassengerReach}
                                            />
                                            {
                                                trayecto.extras && trayecto.extras.length !== 0 &&
                                                <Extras money={trayecto.primaryMoney.alphabeticCode} visitas={visitas} trayecto={trayecto} addExtras={addExtras} quitarExtras={quitarExtras} />                                        
                                            }
                                            {
                                                trayecto.seats &&
                                                <>
                                                    <SeatPicker
                                                        addSeatCallback={addSeatCallback}
                                                        removeSeatCallback={removeSeatCallback}
                                                        rows={trayecto.seats}
                                                        maxReservableSeats={totalSeats}
                                                        alpha
                                                        visible
                                                        selectedByDefault
                                                        loading={loading}
                                                        tooltipProps={{ multiline: true }}
                                                    />
                                                </>
                                            }


                                            <div className="book-form-totals">
                                                <p className='totalAlign'>
                                                    <b>Total: </b> {visitas.total.toFixed(2)} {trayecto.primaryMoney ? trayecto.primaryMoney.alphabeticCode : 'packet.primaryMoney.alphabeticCode' }
                                                </p>
                                            </div>

                                            {
                                                !adding && 
                                                    <button type="button" disabled={disableAddCart || disabled || disabledCanal || !minimumPassengerReach} className="btn-huge btn-wide btn-blue test-add-to-cart-action-enabled" onClick={addCart} >
                                                        {t("addCart")}
                                                    </button>
                                            }
                                            {
                                                adding && 
                                                <button type="button" disabled={disableAddCart || disabled || disabledCanal} className="btn-huge btn-wide btn-blue test-add-to-cart-action-enabled" >
                                                    <Spinner/>
                                                </button>
                                            }
                                            {                                                 
                                                <InfoWeb trayecto = {trayecto} orden={2} publicToken={publicToken}/>
                                            }  
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </section>
            <FooterView/>
        </div>
    );
}
