export const SelectIdaVuelta = ({totalSeats, handleChangeOpcionVuelta, trayectoIdaVuelta, prices, vueltaPrices}) => {
    return(
        <>
        {
            prices && prices.length > 0 &&
            <div className='col-3'>
                <input type="radio" value='soloIda' name='opcionVuelta' disabled={totalSeats > 0} onChange={handleChangeOpcionVuelta} checked={trayectoIdaVuelta ? false : true}></input>
                <label style={{paddingLeft: 7}}>Sólo ida</label>
            </div>
        }
        {
            vueltaPrices && vueltaPrices.length > 0 &&
            <div className='col-8'>
                <input type="radio" value='idaVuelta' name='opcionVuelta' disabled={totalSeats > 0} onChange={handleChangeOpcionVuelta} checked={trayectoIdaVuelta ? true : false}></input>
                <label style={{paddingLeft: 7}}>Ida y vuelta</label>
            </div>            
        }
        </>
    )
}