import  React, { useState } from "react";
import Moment from "moment";
import ICartItem from '../../models/ICartItem'
import Spinner from "reactstrap/es/Spinner";
import { MyTimer } from "components/MyTimer";
import Swal from "sweetalert2";

interface Props {
    money: any
    item: ICartItem
    handleDeleteCartItem: (numero: string) => void
    t: any
    deleting: boolean
    numero: any
}

export const isCartTicket = (item: ICartItem) => {
    return !isCartPacketTicket(item) && !isCartCollaboratorTicket(item) && !isCartCollaboratorPacketTicket(item)
}

export const isCartPacketTicket = (item: ICartItem) => {
    return !!item.packetTicket && !item.belongCollaboratorPacket
}

export const isCartCollaboratorTicket = (item: ICartItem) => {
    return !item.packet_id && item.collaborator_id && !item.belongCollaboratorPacket
}

export const isCartCollaboratorPacketTicket = (item: ICartItem) => {
    return !!item.packet_id && item.belongCollaboratorPacket
}


export const CartItem = ({ item, handleDeleteCartItem, money, t, deleting, numero}: Props) => {
    let name, lines, total, image, tramoHora, extrasLines
    let isPacket = undefined;
    const [expired, setExpired] = useState<boolean>(false)

    if (isCartTicket(item) || isCartCollaboratorTicket(item)) {
        isPacket = false;
        name = item.ticket.stretchName
        lines = item.ticket.lines
        extrasLines = item.ticket.extraLines
        total = item.ticket.visitas.total
        tramoHora = item.ticket.tramoHora
        if(item.ticket) {
            if(item.ticket.urlImage) {
                image = item.ticket.urlImage.replace('http://','https://');
            } else {
                image = 'https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg'
            } 
        }
    } else if (isCartPacketTicket(item) || isCartCollaboratorPacketTicket(item)) {
        isPacket = true;
        name = item.packetTicket.packet.name
        lines = item.packetTicket.lines
        total = item.packetTicket.visitas.total
        tramoHora = item.packetTicket.tramoHora
        if(item.packetTicket) {
            if(item.packetTicket.urlImage) {
                image = item.packetTicket.urlImage.replace('http://','https://');
            } else {
                image = 'https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg'
            } 
        }
    } else {
        console.timeLog('no deberia estar aqui')
    }

    const expiredHandler = () => {
        setExpired(true)
        Swal.fire({
            icon: 'error',
            title: `${t("timedOut")}`,
            html:
            `${t("cartItem")}: <b>${name}</b> ${t("cartItemII")}`
        })
    }
  
    const expire_at = new Date(item.expire_at)
    const treintaMins = new Date(item.created_at)
    treintaMins.setSeconds(treintaMins.getSeconds()+30*60)

    return (
        <>
            <div className="row destinations-details__content sidebar__item">
                <div className="col-sm-8">
                    <div className="row">
                        <div className="col-sm-3">
                            <img src={image} alt={name} style={{paddingBottom: 25}} className="img-fluid" />
                        </div>

                        <div className="col-sm-7">
                            <>
                                <div className="row">
                                    <div className='col-8'>
                                        <h4 style={{opacity: expired ? '30%' : '100%',margin: 0, color: expired ? 'red' : ''}} className={isPacket ? "tramoSeleccionadoPacket" : "tramoSeleccionadoCart"}>{name}</h4>
                                    </div>
                                    <div className="col-3">
                                        {
                                            (!deleting || (item.numero !== numero && deleting)) && 
                                            <button className="btn btn-underline" style={{padding: 0, paddingTop: 5}} onClick={() => handleDeleteCartItem(item.numero)} >{t("delete")}</button>
                                        }
                                        {
                                            item.numero === numero && deleting &&
                                            <div>  
                                                <Spinner/>                                   
                                            </div>
                                        }
                                    </div>
                                </div>                           
                            </>
                            
                            {
                                !(isCartPacketTicket(item) || isCartCollaboratorPacketTicket(item)) &&
                                <div>
                                    <h6>
                                        {tramoHora ? Moment(item.datebook).format('DD-MM-YYYY HH:mm') : Moment(item.datebook).format('DD-MM-YYYY')}  {(item.ticket.origin && item.ticket.destination)? `\u00A0\u00A0|\u00A0\u00A0${item.ticket.origin} ---> ${item.ticket.destination}` : ''} {item.ticket.vuelta ? `\u00A0\u00A0|\u00A0\u00A0Ida y vuelta` : ''}
                                    </h6>
                                </div>
                            }
                            {
                                expired &&
                                <h5 style={{color: 'red', opacity: '85%'}}>{t("necessaryDelete")}</h5>
                            }
                            {
                                item.packetTicket && item.packetTicket.tickets && item.packetTicket.tickets.map(ticket =>
                                    <div key={`packetTicket_${ticket.id}`}>
                                        <h6>
                                            {ticket.stretchName} {ticket.tramoHora ? Moment(ticket.datebook).format('DD-MM-YYYY HH:mm') : Moment(ticket.datebook).format('DD-MM-YYYY')}
                                        </h6>
                                    </div>
                                )
                            }
                            {
                                item.collaboratorTickets && item.collaboratorTickets[0] && item.collaboratorTickets[0].ticket.collaboratorTickets.map(cartTicket =>
                                    <div key={`collaboratorTicket_${cartTicket.id}`}>
                                        <h6>
                                            {cartTicket.stretchName} {cartTicket.tramoHora ? Moment(cartTicket.datebook).format('DD-MM-YYYY HH:mm') : Moment(cartTicket.datebook).format('DD-MM-YYYY')}
                                        </h6>
                                    </div>
                                )
                            }

                        </div>
                        <div className="col-sm-2 col-6">
                        {/*<MyTimer expiryTimestamp={new Date(item.expire_at)} />*/}
                        <MyTimer expiryTimestamp={item.expire_at !== null ? expire_at : treintaMins} expiredHandler={expiredHandler} t={t}/>
                </div>
                    </div>

                </div>

                <div className="col-sm-2 col-8">
                    <div>
                        {
                            lines.map((line) => {
                                return (
                                    <React.Fragment key={`line_${line.id}`}>
                                        {line.quantity !== 0 && line.passengerCategoryName + ": " + line.quantity}
                                        <br></br>
                                    </React.Fragment>
                                )
                            })
                        }
                        <br></br>
                        {
                            extrasLines && extrasLines.length > 0 &&
                               <p>Extras: </p> 
                        }
                        {
                            extrasLines && 
                            extrasLines.map((line) => {                            
                                return (
                                    line.quantity > 0 &&
                                    <React.Fragment key={`extraLine_${line.id}`}>    
                                        <span key={`span_${line.id}`} style={{fontSize: 11}}>- {line.extraName} <strong>x{line.quantity}</strong></span>
                                        <br></br>
                                    </React.Fragment>
                                )                             
                            })
                        }
                    </div>
                </div>

                <div className="col-sm-2 text-right">
                    <p>
                        {total.toFixed(2)} {money} {/*money == 'EUR' ? '€' : '$'*/}
                    </p>
                </div>
            </div>
        </>
    )
}
