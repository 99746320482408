import { useEffect } from "react";
import Stretch from 'models/Stretch';
import Extra from "models/Extra";
import { Vis } from "models/Vis";
import { BoxAddLess } from "./BoxAddLess";

interface Props {
    trayecto: Stretch
    visitas?: Vis
    money?: any
    addExtras?(event: any): void
    quitarExtras?(event: any): void
}

export const Extras = ({trayecto, addExtras, quitarExtras, visitas, money}: Props) => {

    const extras = trayecto.extras

    useEffect(() => {
        extras.forEach(extra => {
            extra.quantity = 0;
        })  
    },[extras]); 
    
    return (
        <>
            {
                extras && extras.length !== 0 && !extras[0].disabled_at &&
                <h3 className="extrasHeading">
                    Extras
                </h3>
            }
            {
                extras.map((extra: Extra, index) => {
                    const precioExtra = extra.price + extra.price * extra.tax.value
                    if(!extra.disabled_at && extra.price > 0) {
                        return (
                            <BoxAddLess key={extra.id} name={extra.name} priceUnitary={precioExtra} prices={visitas.extrasEdit.prices} disabled={visitas.extrasEdit.quantityExtras && visitas.extrasEdit.quantityExtras[index] === 0} quantities={visitas.extrasEdit.quantityExtras} description={extra.description} money={money} index={index} substract={quitarExtras} add={addExtras}/>                         
                        )
                    } else {
                        return null
                    }
                })
            }
        </>
    )
}