import Cart from "models/Cart";
import { PaymentGateway } from "./paymentGateway";
import { getComissionData, getMerchant_location_id, getPaymentToken, modalError } from "./Request";
import * as Sentry from "@sentry/react";
import { currencies } from "Modules/currencies";
import Swal from "sweetalert2";

export  class PaynoPain extends PaymentGateway{

    set_url_post(url_post): void {
      this.url_post = url_post;
    }
    set_url_ok(url_ok): void {
       this.url_ok = url_ok;

    }
    set_url_ko(url_ko): void {
       this.url_ko = url_ko;
    }

    tokenTicket: string;
    publicToken: string;
    urlBasePost: string;
    namePasarela: string;
    totalAmount: number;
    description: string;
    url_post: string;
    url_ok: string;
    url_ko: string;
    name: string;
    surname: string;
    telephone: string;
    email: string;
    additional_info: any;
    money: string;
    cart: Cart;
    t:any
    environment: any; // dev o prod
    idioma: any;

    constructor(tokenTicket:string, publicToken: string,  totalAmount: number, description: string, name: string, surname: string, telephone: string, email: string, additional_info: any, money: string, cart: Cart,t: any) {
        super();
        this.tokenTicket = tokenTicket;
        this.publicToken = publicToken;
        this.namePasarela = 'PAYNOPAIN';
        this.totalAmount = totalAmount;
        this.description = description;
        this.name = name;
        this.surname = surname;
        this.telephone = telephone;
        this.email = email;
        this.additional_info = additional_info;
        this.money = money;
        this.cart = cart;
        this.t = t;
        this.environment = process.env.REACT_APP_ENVIRONMENT 
        try {
            this.idioma= localStorage.getItem('idioma');
        } catch(e) {
            this.idioma = null
        }
      }

    async pay(): Promise<void> {
           
      let body = await this.mountBody()
      let result = await getPaymentToken(body, this.publicToken, this.namePasarela, this.environment);

      let monedaPago = currencies.filter(c => c.numericCode.toString() === result.currency)

      let url_redirect = ''
      if (result.status === "CREATED" && !result.paid && monedaPago[0] && monedaPago[0].alphabeticCode === this.money) { 
        url_redirect = `https://api.paylands.com/v1/sandbox/payment/process/${result.token}`
        if(this.idioma) {
          url_redirect = url_redirect + `?lang=${this.idioma}` // Para que el widget de pago aparezca en el idioma si lo hubiese como variable local
        }
        window.location.href = url_redirect
      } else if(monedaPago[0] && monedaPago[0].alphabeticCode !== this.money) {
        Swal.fire({
            icon: 'error',
            title: 'Error - 499',
            text: "El pago no se puede realizar.",
            footer: 'Contacte con la empresa para avisarle del error.'
          })
          Sentry.captureException(new Error(`Paynopain - La moneda del servicio y la de las experiencias no coinciden.`));
      } else {
        let message = '';
        switch (result.error_code) {
          default: 
            message = `${this.t("errorduringPayment")} - `+result.details.replace(" Please, review documentation.", "")
      }

      Sentry.captureException(new Error(`Paynopain - ${message}`));
      modalError(message);
    }
  }

  async mountBody() {

    const tokenTicket = this.tokenTicket;
    const amount = this.totalAmount*100;
    const additional_info = this.additional_info;
    const url_post = this.url_post;
    const url_ok = this.url_ok;
    const url_ko = this.url_ko;
    const description = this.description;
    const cart = this.cart;
    const publicToken = this.publicToken
      
    const tickets = [...cart.cartTickets, ...cart.cartPacketTickets, ...cart.cartCollaboratorTickets, ...cart.cartCollaboratorPacketTickets]
    let min_expired = 9999999999999; // Una cantidad muy elevada para luego ir comparando y obtener el menor tiempo
    for(let i = 0; i < tickets.length; i++){
        let date_expire_at = new Date(tickets[i].expire_at)
        let ahora = new Date()
        let dif = (date_expire_at.getTime() - ahora.getTime())/1000
        if(dif < min_expired) {
            min_expired = dif;
        }
    }
  
    min_expired = Math.round(min_expired)        
      
    const cartCollaboratorTickets = cart.cartCollaboratorTickets;
    const cartCollaboratorPacketTickets = cart.cartCollaboratorPacketTickets
    const cartTickets = cart.cartTickets 
    const cartPacketTickets = cart.cartPacketTickets
    let totalSeller = 0

    let merchants = []

    for(let i = 0; i < cartTickets.length; i++) {// Estos son los tickets de experiencias propias, se sumará el total de todas ellas
      totalSeller = totalSeller + cartTickets[i].ticket.total
    }

    for(let i = 0; i < cartPacketTickets.length; i++) {
      const cartPacketTicket = cartPacketTickets[i]
      const lines = cartPacketTicket.packetTicket.lines
      if(cartPacketTicket.collaboratorTickets.length === 0) {
        totalSeller = totalSeller + cartPacketTicket.packetTicket.total
      } else {
        totalSeller = totalSeller + cartPacketTicket.packetTicket.total
        // Por cada servicio en colaboración en el paquete
        for(let j = 0; j < cartPacketTicket.packetTicket.collaboratorTickets.length; j++) {
          const collaboratorTicket = cartPacketTicket.packetTicket.collaboratorTickets[j]
          let totalOperator = 0
          let prices = collaboratorTicket.stretch.prices
          for(let k = 0; k < lines.length; k++) {
            const line = lines[k]
            let categoryName = line.passengerCategoryName
            let priceCategory = prices.filter(price => price.passengerCategory.name.toLowerCase() === categoryName.toLowerCase())
            totalOperator = totalOperator + (priceCategory[0].packetPriceNet + priceCategory[0].packetPriceNet * collaboratorTicket.stretch.tax.value) * line.quantity
          }
          let error = false
          const operator_id = collaboratorTicket.operator_id
          const res = await getMerchant_location_id(publicToken, operator_id)
          if(res.code === 50 && res.msg === 'Error') error = true
          let operator =  {
            merchant_location_id: "2A886039-D7CA-4532-A836-25ABD7618A07", //res.toString()
            amount: (+totalOperator.toFixed(2)) * 100,
            net_amount: (+totalOperator.toFixed(2)) * 100,
            description: `operator ${operator_id}`
          }
          if(!error) merchants.push(operator)
          totalSeller = totalSeller - (+(totalOperator.toFixed(2)))
        }
      }
    }

    for(let i = 0; i < cartCollaboratorTickets.length; i++) {
      const collaboratorTicket = cartCollaboratorTickets[i]
      const operator_id = collaboratorTicket.ticket.operator_id
      let error = false
      const res = await getMerchant_location_id(publicToken, operator_id)
      if(res.code === 50 && res.msg === 'Error') error = true
      let operator =  {
        merchant_location_id: "2A886039-D7CA-4532-A836-25ABD7618A07", // res.toString()
        amount: collaboratorTicket.ticket.totalNet * 100,
        net_amount: collaboratorTicket.ticket.totalNet * 100,
        description: `operator ${operator_id}`
      }
      if(!error) merchants.push(operator)
      totalSeller = totalSeller + (collaboratorTicket.ticket.total - collaboratorTicket.ticket.totalNet)
    }

    /*cartCollaboratorPacketTickets.forEach(async (collaboratorPacketTicket)  => {
      const operator_id = collaboratorPacketTicket.ticket.operator_id
      let operator =  {
        merchant_location_id: "2A886039-D7CA-4532-A836-25ABD7618A07",
        amount: collaboratorPacketTicket.ticket.totalNet * 100,
        net_amount: collaboratorPacketTicket.ticket.totalNet * 100,
        description: `operator ${operator_id}`
      }
      merchants.push(operator)
      totalSeller = totalSeller + (collaboratorPacketTicket.ticket.total - collaboratorPacketTicket.ticket.totalNet)
    }) */


    //let commissionSeller = (0.049 + ((seller_channel_id.additionalCommission) ? seller_channel_id.additionalCommission / 100 : 0))

    let commissionSeller = 0.049
    let totalToSeller = Math.round((totalSeller - (amount/100) * commissionSeller) * 100)
    if(totalToSeller < 0) {
      Sentry.captureException(new Error("Paynopain - Seller commissions higher than total"));
    }
    let error = false
    let res = await getMerchant_location_id(publicToken, cart.client.id)
    if(res.code === 50 && res.msg === 'Error') error = true
    if(totalToSeller > 0) {
      let seller =  {
        merchant_location_id: "2A886039-D7CA-4532-A836-25ABD7618A07", //res.toString()
        amount: totalToSeller,
        net_amount: totalToSeller,
        description: "seller"
      }
      if(!error) merchants.push(seller)
    }

    let ticando = {
      merchant_location_id: "2A886039-D7CA-4532-A836-25ABD7618A07",
      amount: 0,
      net_amount: amount * 0.049,
      description: "ticando-marketplace"
    }

    merchants.push(ticando)

    return JSON.stringify({
        signature: "",
        reference: `Ticando Order - ${tokenTicket}`,
        amount: Math.round(amount),
        operative: "AUTHORIZATION",
        additional: additional_info,
        service: "",
        secure: true,
        url_post: url_post,
        url_ok: url_ok,
        url_ko: url_ko,
        description: description,
        save_card: false,
        template_uuid: null,
        dcc_template_uuid: null,
        /*extra_data: {
            marketplace : merchants
        } */
       expires_in: min_expired 
    });
  }
}